import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-list-item',
  templateUrl: './card-list-item.component.html',
  styleUrls: ['./card-list-item.component.scss']
})
export class CardListItemComponent {
  @Input() primaryText?: string;
  @Input() secondaryText?: string;
  @Input() isActive = false;
  @Input() actionText?: string; // Optional action text to display in the component
  @Output() onActionClick = new EventEmitter<any>(); // Event emitter for action click

  constructor() { }
}
