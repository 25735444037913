import { Component, Input } from '@angular/core';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { useToastStore } from 'src/app/store/toast.store';
import { UserState, useUserStore } from 'src/app/store/user.store';
import {
  AnalyticsManagerService,
  Events,
} from 'src/app/shared/services/analytics-manager.service';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tooltip } from 'src/app/constants/constants';

@Component({
  selector: 'campaign-filter-options-modal',
  templateUrl: './campaign-filter-options.component.html',
  styleUrl: './campaign-filter-options.component.scss',
})
export class CampaignFilterOptionsComponent {
  user: UserState;
  @Input() isVisible: boolean;

  // Lead List
  @Input() leadListLoadingText = 'Syncing List Lists';
  @Input() isSyncingLeadList: boolean;
  leadList: any;
  isLeadListSyncInProgress: boolean = false;
  isSyncLeadListMode: boolean;
  learnMoreTooltip = Tooltip.ADD_LINKEDIN_LEAD_LIST;
  chooseFiltersPrompt =
    'Would you like to use a Linkedin Lead List or custom filters?';
  syncLeadlistPrompt = "Let's sync your Linkedin Lead Lists";

  constructor(
    private requestHelpersService: RequestHelpersService,
    private userService: UserProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.isVisible = useModalManagerStore.getState().campaignFilterOptions;
    this.isSyncLeadListMode =
      useModalManagerStore.getState().isSyncLeadListMode;
  }

  onCloseModalClick = () => {
    useModalManagerStore.setState({
      campaignFilterOptions: false,
      isSyncLeadListMode: false,
    });
  };

  onLinkedinLeadListClick() {
    AnalyticsManagerService.recordButtonClick(
      Events.addLeadListCampaignClicked
    );
    this.user = useUserStore.getState();
    this.leadList = this.user?.linkedin_lead_list;
    if (this?.leadList?.length > 0) {
      useModalManagerStore.setState({
        campaignFilterOptions: false,
        salesNavigatorLeadListCampaign: true,
      });
      this.router.navigate(['add-campaign'], { relativeTo: this.route });
    } else {
      useModalManagerStore.setState({ isSyncLeadListMode: true });
    }
  }

  onCustomFiltersClick() {
    AnalyticsManagerService.recordButtonClick(
      Events.addCustomFiltersCampaignClicked
    );
    useModalManagerStore.setState({
      campaignFilterOptions: false,
      salesNavigatorCustomFiltersCampaign: true,
    });

    this.router.navigate(['add-campaign'], { relativeTo: this.route });
  }

  navigatorToAddCampaign() {
    this.router.navigate(['add-campaign'], {
      queryParams: { isLeadListsFilter: true },
      relativeTo: this.route,
    });
  }

  getSyncStatus() {
    let interval = null;
    let currentPollCount = 0;
    let errorMessage =
      'Encountered an error syncing your list, please try again';

    const pollSyncStatus = () => {
      this.userService.getUserProfile();
      const user = useUserStore.getState();
      let isSyncActive = user.lead_sync_active;
      this.leadList = user.linkedin_lead_list;
      currentPollCount++;
      if (isSyncActive) {
        // TODO: handle error if sync status doesnt change after 30 seconds
      } else if (
        !isSyncActive &&
        currentPollCount > 20 &&
        !this.leadList.length
      ) {
        clearInterval(interval);
        useModalManagerStore.setState({ isSyncLeadListMode: false });
        useToastStore.setState({
          isVisible: true,
          content: errorMessage,
          variant: 'danger',
        });
      } else if (
        this.leadList.length > 0 &&
        !isSyncActive &&
        currentPollCount > 5
      ) {
        clearInterval(interval);
        this.isLeadListSyncInProgress = false;
        this.onCloseModalClick();
        this.navigatorToAddCampaign();
        useModalManagerStore.setState({ isSyncLeadListMode: false });
        useToastStore.setState({
          isVisible: true,
          content: 'Lead lists synced successfully',
          variant: 'success',
        });
      }
    };

    // Start the interval
    interval = setInterval(pollSyncStatus, 5000);
  }

  onSyncLinkedinLeadListClick() {
    useModalManagerStore.setState({ isSyncLeadListMode: true });
    const jwt = this.requestHelpersService.getJwt();

    AnalyticsManagerService.recordButtonClick(Events.runLeadListClicked);
    // @ts-ignore
    window.location = `pipa-app://authorization=${jwt}&initialTask=run-leadList`;
    this.isLeadListSyncInProgress = true;
    this.getSyncStatus();
  }

  ngOnInit(): void {
    useModalManagerStore.subscribe((state) => {
      this.isVisible = state.campaignFilterOptions;
      this.isSyncLeadListMode = state.isSyncLeadListMode;
    });
  }
}
