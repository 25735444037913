import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { Campaign } from "../campaigns.model";
import * as CampaignsActions from "./campaigns.actions";
import * as fromRoot from "../../store/app.reducer";

export interface State {
  campaigns: Campaign[];
  isLoading?: boolean;
  isLoadingSuccess?: boolean;
  isLoadingFailure?: boolean;
  error?: any;
}

const initialState: State = {
  campaigns: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null,
};

export const campaignsReducer = createReducer(
  initialState,
  on(CampaignsActions.getCampaigns, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.getCampaignsSuccess, (state, { payload }) => ({
    ...state,
    campaigns: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.getCampaignsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),

  on(CampaignsActions.addCampaign, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.addCampaignSuccess, (state, { payload }) => ({
    ...state,
    campaigns: [...state.campaigns, payload],
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.addCampaignFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),

  on(CampaignsActions.updateCampaign, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.updateCampaignSuccess, (state, { payload }) => ({
    ...state,
    campaigns: state.campaigns.map(campaign => {
      if (payload.id === campaign.id) {
        return payload;
      }
      return campaign;
    }),
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.updateCampaignFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),

  on(CampaignsActions.deleteCampaign, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.deleteCampaignSuccess, (state, { payload }) => ({
    ...state,
    campaigns: state.campaigns.filter(campaign => campaign.id !== payload.id),
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(CampaignsActions.deleteCampaignFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),
);

export const campaignsFeatureKey = "campaigns";

export const campaignsFeatureState = createFeatureSelector<fromRoot.AppState>(campaignsFeatureKey);
