import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectModule, NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'dropdown-select',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [NgSelectModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true,
    },
  ],
  templateUrl: './dropdown-select.component.html',
})
export class DropdownSelectComponent implements ControlValueAccessor {
  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;
  @Input() options: any[] = [];
  @Input() disabled?: boolean;
  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'id';
  @Input() placeholder: string = 'Select an option';
  @Input() defaultValue: any;
  @Input() isMultiple: boolean = false;
  @Input() isDarkTheme?: boolean = true;
  selectedItem: any;


  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  // Callback for form control changes
  private onChangeCallback: (value: any) => void = () => { };
  private onTouchedCallback: () => void = () => { };

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // Handle the default value at component initialization
    if (this.defaultValue) {
      this.setSelectedItem(this.defaultValue);
    }
  }

  ngAfterViewInit() {
    if (this.defaultValue) {
      setTimeout(() => {
        this.selectionChanged.emit(this.selectedItem);
      }, 0);
    }
  }

  // ControlValueAccessor Methods (Reactive Forms)

  writeValue(value: any): void {
    // Handle setting the value from the form model
    if (value !== undefined && value !== null) {
      this.setSelectedItem(value);
    }
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.detectChanges();
  }

  onChange(selectedValue: any) {
    this.selectedItem = selectedValue;
    this.onChangeCallback(selectedValue); // Update the reactive form control
    this.onTouchedCallback(); // Mark the control as touched
    this.selectionChanged.emit(selectedValue); // Emit for non-form usages
  }

  compareWithFn = (o1: any, o2: any) =>
    o1 && o2 ? o1[this.bindValue] === o2[this.bindValue] : o1 === o2;

  clearSelected() {
    this.selectedItem = [];
    // close the dropdown
    this.ngSelect.close();
  }

  private setSelectedItem(value: any) {
    // Helper method to set and emit the selected item
    this.selectedItem = this.options.find(
      (option) => option[this.bindValue] === value
    );
  }
}
