<div class="form-group">
  <label
    *ngIf="label"
    class="dropdown_label s5"
    [ngStyle]="{
      color: isDarkTheme ? '#fff' : '#0A2540'
    }"
  >
    {{ label }}
  </label>
  <div
    class="dropdown_wrapper"
    [ngClass]="{
      'dark-theme-wrapper': isDarkTheme,
      'intl-dropdown-wrapper': isIntlCodeInput
    }"
  >
    <select
      ngDefaultControl
      name="{{ name }}"
      id="{{ id }}"
      class="dropdown_menu"
      [ngClass]="{
        'dark-theme': isDarkTheme,
        'intl-dropdown': isIntlCodeInput,
        'dark-theme-opaque': theme === 'dark'
      }"
      [ngStyle]="{

        'background-image': isIntlCodeInput ? 'none' : isDarkTheme ? 'url(assets/icons/arrow-down-white.svg)' : 'url(assets/icons/arrow-down-purple.svg)',
      }"
    >
      <option
        *ngIf="allowRemoveSelected"
        value=""
        [attr.default]="!defaultValue?.length"
      >
        {{ !defaultValue ? placeholder : "Remove selection" }}
      </option>
      <option
        [selected]="option.value === defaultValue"
        class="dropdown_option"
        *ngFor="let option of options"
        [value]="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</div>
