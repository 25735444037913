import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserState } from 'src/app/store/user.store';
import { useNavigationStore as navStore } from 'src/app/store/navigation.store';
import { userProfileFeatureKey } from 'src/app/store/user/user.reducer';
import { Subject, takeUntil, Observable } from 'rxjs';
import * as fromRoot from '../../store/app.reducer';
import { HIDDEN_NAVIGATION_ROUTES } from 'src/app/constants/hidden-navigation-routes.const';
import { LinkedinConnectionsService } from 'src/app/services/linkedin-connections/linkedin-connections.service';
import { useLinkedinConnectionsStore } from 'src/app/store/linkedin-connections';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { ActivityMappingService } from 'src/app/shared/services/activity-mapping.service';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userName: string;
  user: UserState;
  productId: number;
  runCampaignAllowed: boolean;
  activeRoute: string;
  isMobile: boolean;
  transparent = false;
  appleIcon = 'assets/icons/apple-icon.svg';
  cookieExpiry: string;
  hasRawConnections: boolean;
  showAuthenticate: boolean = false;
  profileImage: string;

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromRoot.AppState>,
    private linkedinConnectionService: LinkedinConnectionsService,
    private activityMappingService: ActivityMappingService
  ) { }

  ngOnInit() {
    this.transparent = HIDDEN_NAVIGATION_ROUTES.includes(
      navStore.getState().currentPage
    );

    this.getUser();

    navStore.subscribe(
      (s) => s.currentPage,
      (newActiveRoute) => {
        this.activeRoute = newActiveRoute;
        this.transparent = HIDDEN_NAVIGATION_ROUTES.includes(newActiveRoute);
      }
    );

    if (this.transparent || window.innerWidth <= 800) {
      this.runCampaignAllowed = false;
    } else {
      this.runCampaignAllowed = true;
    }

    this.checkViewport();
    window.addEventListener('resize', this.checkViewport.bind(this));


    if (this.transparent || this.isMobile) {
      this.runCampaignAllowed = false;
    } else {
      this.runCampaignAllowed = true;
    }
  }

  getUser() {
    this.store
      .select(userProfileFeatureKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe((userState) => {
        this.user = userState;
        this.userName = userState.full_name;
        this.productId = userState.product_id;
        this.cookieExpiry = userState.cookie_expiry;
        this.hasRawConnections = userState.has_raw_connections;
      });
  }

  searchConnections = (searchString: string): Observable<any> => {
    return this.linkedinConnectionService.getLinkedinConnections({
      page: 0,
      pageSize: 10,
      searchString: searchString
    });
  };

  handleSelection(item: any) {
    useLinkedinConnectionsStore.setState({
      activeConnection: [item], // Wrap in an array to match the expected type
      isActivityLoading: true,
    });
    this.linkedinConnectionService
      .getLinkedinConnectionActivity(item.id)
      .subscribe((activities: any) => {
        const activityArray = activities.map((activity) => {
          const config = this.activityMappingService.isActivityMappedById(
            activity
          )
            ? this.activityMappingService.resolveActivityMappingById(
              activity,
              item.connection_name
            )
            : {
              activityType:
                this.activityMappingService.resolveActivityMessage(
                  activity.activity,
                  item.connection_name
                ),
              message:
                !!activity.details &&
                  !this.activityMappingService.isPredefinedMessage(activity)
                  ? activity.details
                  : null,
              icon: this.activityMappingService.resolveActivityIcon(
                activity.activity
              ),
              createdAt: activity.created_at,
            };
          return config;
        });

        useLinkedinConnectionsStore.setState({
          connectionActivity: activityArray.filter(
            (activity) => activity !== undefined
          ),
          isActivityLoading: false,
        });
      }
      );
    useLinkedinConnectionsStore.setState({ connectionId: item.id });
    useModalManagerStore.setState({ activityLog: true });
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.checkViewport.bind(this));
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkViewport(): void {
    this.isMobile = window.innerWidth <= 1024; // Check if the screen is tablet-sized or smaller
  }

}
