<ng-template appPlaceholder></ng-template>
<app-modal
  [visible]="isVisible"
  [usePadding]="false"
  class="activity-log-modal"
>
  <div class="activity-log-modal-content flex">
    <div class="flex__column profile-details activity-log-column">
      <div class="profile-header flex__row--space-between">
        <img
          src="assets/icons/x-close-black.svg"
          class="_close-icon"
          alt="close-icon"
          (click)="onClose()"
        />
        <a
          rel="noreferrer"
          target="_blank"
          href="{{ connection?.profile_link }}"
          class="cta-link"
          title="View Linkedin Profile"
        >
          <img src="assets/icons/linkedin-icon.svg" class="linkedin-icon" />
        </a>
      </div>
      <div class="profile-card-wrapper flex__center fx-1">
        <profile-vertical-card
          [image]="connection?.image_url"
          [fullName]="connection?.connection_name"
          [jobTitle]="connection?.job_title"
          [connectedOn]="connection?.connected_on_date | dateAgo"
          [email]="connection?.email"
          [phone]="connection?.phone_number"
          [location]="connection?.location"
          [company]="connection?.company_name"
        ></profile-vertical-card>
      </div>
      <div class="activity-log-actions flex__column--space-between fx-1">
        <div class="activity-actions">
          <app-button
            *ngIf="connection?.stage !== 'resolved'"
            class="outlined"
            [fullWidth]="true"
            label="Mark as complete"
            (click)="showPrompt(markAsCompletePrompt)"
          ></app-button>
          <app-button
            *ngIf="connection?.stage === 'resolved'"
            class="outlined"
            [fullWidth]="true"
            label="Mark as active"
            (click)="showPrompt(markAsActivePrompt)"
          ></app-button>
          <app-button
            *ngIf="!!connection?.profile_urn"
            [class]="isSendMessageActive ? 'primary' : 'outlined'"
            [fullWidth]="true"
            label="Send Message"
            (click)="onToggleMessageInput()"
            style="margin-top: 10px"
          ></app-button>
        </div>
        <div class="activity-input-wrapper">
          <div *ngIf="isSendMessageActive" class="input-wrapper">
            <app-text-area
              (input)="onSendMessageChange($event)"
              [value]="message"
              label="Send message"
            ></app-text-area>
            <app-button
              class="secondary send-btn flex__row--end"
              label="Send"
              [fullWidth]="true"
              loadingLabel="Sending"
              [isLoading]="isLoadingMessageSend"
              [disabled]="!message.length || isLoadingMessageSend"
              (click)="onSendMessageClick()"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex__column activity-details activity-log-column">
      <div class="activity-header">
        <h4>Activity</h4>
      </div>
      <ng-container
        *skeleton="
          isLoading;
          repeat: 10;
          className: 'mb-2';
          height: '70px';
          width: '100%';
          borderRadius: '10px'
        "
      >
        <activity-log-cell
          *ngFor="let activity of activityData"
          [activityType]="activity?.activityType"
          [activityDescription]="activity?.message"
          [icon]="activity?.icon"
          [dateTime]="activity?.createdAt | date : 'MMMM d, y h:mm a'"
        ></activity-log-cell>
      </ng-container>
      <div *ngIf="!activityData?.length && !isLoading">
        <activity-log-cell
          [activityDescription]="'Sent invitation to connect'"
          [icon]="'assets/icons/send-check.svg'"
          [dateTime]="connection?.created_at | dateAgo"
        ></activity-log-cell>
        <activity-log-cell
          [activityDescription]="'Prospect accepted your invitation to connect'"
          [icon]="'assets/icons/link.svg'"
          [dateTime]="connection?.connected_on_date"
        ></activity-log-cell>
      </div>
    </div>
  </div>
</app-modal>
