import { Component, Input } from '@angular/core';

@Component({
  selector: 'box-cell',
  template: `
    <div
      class="box-cell-container flex__column--center  {{
        isActive && 'active'
      }}"
    >
      <span class="box-value">{{ value }}</span>
      <span class="box-label">{{ label }}</span>
    </div>
  `,
  styleUrls: ['./box-cell.component.scss'],
})
export class BoxCellComponent {
  @Input() value: number | string;
  @Input() label: string;
  @Input() isActive?: boolean;

  constructor() {}
}
