import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent implements OnInit {
  @Input() header: string;
  @Input() children: any;
  @Input() activeClass: string;
  @Input() toggleKey: string;
  @ViewChild('accordian', { static: true }) accordianDiv: ElementRef;
  isActive: boolean;
  targetElement: any;
  expansionPanel: any;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.activeClass = localStorage.getItem(this.toggleKey);
    if (!this.activeClass) this.activeClass = "inactive";

    this.targetElement = this.renderer.selectRootElement(this.accordianDiv.nativeElement);
    const text = this.renderer.createText(this.header);
    this.renderer.appendChild(this.targetElement, text);
    this.targetElement.classList.toggle(this.activeClass);

    this.isActive = this.targetElement.classList.contains("active");
    this.expansionPanel = this.targetElement.nextElementSibling;
  }

  ngAfterViewInit() {
    if (!this.isActive) {
      this.renderer.setStyle(this.expansionPanel, 'max-height', null);
    } else {
      this.renderer.setStyle(this.expansionPanel, 'max-height', `${this.expansionPanel.scrollHeight}px`);
    }
  }

  setToggleKey() {
    if (this.activeClass === "active") {
      this.renderer.removeClass(this.targetElement, this.activeClass);
      localStorage.setItem(this.toggleKey, "inactive");
    } else {
      this.renderer.removeClass(this.targetElement, this.activeClass);
      localStorage.setItem(this.toggleKey, "active");
    }
    this.activeClass = localStorage.getItem(this.toggleKey);
    this.targetElement.classList.toggle(this.activeClass);
  }

  toggleAccordian() {
    this.setToggleKey();
    this.expansionPanel = this.targetElement.nextElementSibling;
    if (this.expansionPanel.style.maxHeight) {
      this.expansionPanel.style.maxHeight = null;
    } else {
      this.expansionPanel.style.maxHeight = this.expansionPanel.scrollHeight + "px";
    }
  }

}
