import { createPipaStore } from './store.helpers';

export type Route =
  | 'campaigns'
  | 'leads'
  | 'message-templates'
  | 'billing-plans'
  | 'downloads'
  | 'dashboard'
  | 'user-profile';


export const Routes: { [key: string]: Route } = {
  campaigns: 'campaigns',
  leads: 'leads',
  messageTemplates: 'message-templates',
  billingPlans: 'billing-plans',
  downloads: 'downloads',
  dashboard: 'dashboard',
  userProfile: 'user-profile',
};

interface NavigationState {
  currentPage: Route;
  setCurrentPage: (page: Route) => void;
}

export const useNavigationStore = (() =>
  createPipaStore<NavigationState>((set, get) => ({
    currentPage: Routes.campaigns,
    setCurrentPage: (page) => set({ currentPage: page }),
  })))();
