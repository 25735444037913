import { Component } from '@angular/core';
import { NgForm } from "@angular/forms";
import { take } from 'rxjs';
import { WaitlistService } from 'src/app/services/waitlist-service/waitlist-service.api.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { useWaitlistStore } from 'src/app/store/waitlist.store';

@Component({
  selector: 'app-windows-waitlist-modal',
  templateUrl: './windows-waitlist-modal.component.html',
  styleUrls: ['./windows-waitlist-modal.component.scss']
})
export class WindowsWaitlistModalComponent {
  visible: boolean
  email: string
  name: string
  loading?: boolean
  success?: boolean
  waitlist_name = 'windows'
  windowsWaitlistForm: NgForm

  constructor(
    private waitlistService: WaitlistService
  ) {
    useWaitlistStore.subscribe(s => s.addToWaitlist, store => {
      this.loading = store.loading
      this.success = store.success
    })

    useModalManagerStore.subscribe(s => s, store => {
      this.visible = store.windowsWaitlist
    })
  }

  onClose() {
    useModalManagerStore.getState().toggleModal('windowsWaitlist', false)
  }

  onSubmit(form: NgForm) {
    if (this.success) return;

    this.windowsWaitlistForm = form;

    const email = form.value.email;
    const name = form.value.name;
    const req$ = this.waitlistService.addToWaitlist({ email, name, waitlist_name: 'windows' })
    req$.pipe(take(1)).subscribe();
  }

}
