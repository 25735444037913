<div
  *ngIf="!!activityType || !!activityDescription"
  class="activity-log-container flex _easeTopToBottom"
>
  <div class="activity-content-wrapper flex__center">
    <div class="activity-log-icon-box flex__center">
      <img
        [style]="isCustomIcon(icon) ? 'width: 28px' : ''"
        src="{{ icon }}"
        class="activity-log-icon"
        alt="activity-log-icon"
        draggable="false"
      />
    </div>

    <div class="activity-log-content flex__column">
      <span class="activity-log-type">
        {{ activityType }}
      </span>
      <span class="activity-log-description">
        {{ activityDescription }}
      </span>

      <span class="activity-log-date">
        {{ dateTime }}
      </span>
    </div>
  </div>
</div>
