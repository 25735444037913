import { Component, Input } from '@angular/core';
import { useAlertBannerStore } from 'src/app/store/alert-banner.store';

@Component({
  selector: 'alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrl: './alert-banner.component.scss',
})
export class AlertBannerComponent {
  @Input() isVisible: boolean = false;
  @Input() message: string;
  @Input() variant: 'warning' | 'error' = 'warning';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() messageClickable: boolean = false;
  warningIcon = 'assets/icons/warning.svg';
  dangerIcon = 'assets/icons/circle-exclamation-filled.svg';
  activeIcon: string = this.warningIcon;

  constructor() {
    // useAlertBannerStore.subscribe((state) => {
    //   this.message = state.message;
    //   this.variant = state.variant;
    //   this.isVisible = state.isVisible;
    // });
  }

  onClose() {
    this.isVisible = false;
    // useAlertBannerStore.setState({
    //   isVisible: false,
    //   message: '',
    //   variant: 'warning',
    // });
  }

  ngOnInit(): void {
    if (this.variant === 'error') {
      this.activeIcon = this.dangerIcon;
    }
  }
}
