import { trigger, style, animate, transition } from '@angular/animations';

export const drawerAnimation = trigger('drawerAnimation', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }), // Drawer starts off-screen
    animate('300ms ease-in-out', style({ transform: 'translateX(0)' })) // Slides into view
  ]),
  transition(':leave', [
    animate('300ms ease-in-out', style({ transform: 'translateX(100%)' })) // Slides out of view
  ])
]);
