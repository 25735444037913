import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text',
  template: `
    <span class="{{variant}} {{bold ? 'bold' : ''}} {{faded ? 'faded' : ''}}" [style]="style">
      <ng-content></ng-content>
    </span>
  `
})
export class TextComponent implements OnInit {

  @Input()
  variant: 's1' | 's2' | 's3' | 's4' | 's5' = 's5'
  @Input()
  bold: boolean
  @Input()
  faded: boolean
  @Input()
  style: string = ""

  constructor() {}
  ngOnInit(): void { }
}
