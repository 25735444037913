import { Campaign } from "../campaigns.model";
import { createAction, props } from '@ngrx/store';

export const GET_CAMPAIGNS = '[Campaigns] Get Campaigns';
export const GET_CAMPAIGNS_SUCCESS = '[Campaigns] Get Campaigns Success';
export const GET_CAMPAIGNS_FAILURE = '[Campaigns] Get Campaigns Failure';

export const ADD_CAMPAIGN = '[Campaigns] Add Campaign';
export const ADD_CAMPAIGN_SUCCESS = '[Campaigns] Add Campaign Success';
export const ADD_CAMPAIGN_FAILURE = '[Campaigns] Add Campaign Failure';

export const UPDATE_CAMPAIGN = '[Campaigns] Update Campaign';
export const UPDATE_CAMPAIGN_SUCCESS = '[Campaigns] Update Campaign Success';
export const UPDATE_CAMPAIGN_FAILURE = '[Campaigns] Update Campaign Failure';

export const DELETE_CAMPAIGN = '[Campaigns] Delete Campaign';
export const DELETE_CAMPAIGN_SUCCESS = '[Campaigns] Delete Campaign Success';
export const DELETE_CAMPAIGN_FAILURE = '[Campaigns] Delete Campaign Failure';

export const getCampaigns = createAction(
  GET_CAMPAIGNS,
);

export const getCampaignsSuccess = createAction(
  GET_CAMPAIGNS_SUCCESS,
  props<{ payload: Campaign[] }>()
);

export const getCampaignsFailure = createAction(
  GET_CAMPAIGNS_FAILURE,
  props<{ error: any }>()
);

export const addCampaign = createAction(
  ADD_CAMPAIGN,
  props<any>()
);

export const addCampaignSuccess = createAction(
  ADD_CAMPAIGN_SUCCESS,
  props<any>()
);

export const addCampaignFailure = createAction(
  ADD_CAMPAIGN_FAILURE,
  props<{ error: any }>()
);

export const updateCampaign = createAction(
  UPDATE_CAMPAIGN,
  props<any>()
);

export const updateCampaignSuccess = createAction(
  UPDATE_CAMPAIGN_SUCCESS,
  props<any>()
);

export const updateCampaignFailure = createAction(
  UPDATE_CAMPAIGN_FAILURE,
  props<{ error: any }>()
);

export const deleteCampaign = createAction(
  DELETE_CAMPAIGN,
  props<any>()
);

export const deleteCampaignSuccess = createAction(
  DELETE_CAMPAIGN_SUCCESS,
  props<any>()
);

export const deleteCampaignFailure = createAction(
  DELETE_CAMPAIGN_FAILURE,
  props<{ error: any }>()
);
