import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { useUserStore } from 'src/app/store/user.store';
import { VerifyPhoneService } from '../../../services/verify-phone-service/verify-phone.api.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { useToastStore } from 'src/app/store/toast.store';

@Component({
  selector: 'app-verify-phone',
  template: `
    <div *ngIf="verified === false" class="verify-number-wrapper">
      <h5 class="verify-help-block s4">Verify your profile</h5>
      <button (click)="onVerify()" class="warning" id="verify-phone-btn">
        Verify
        <img
          src="{{ phoneIcon }}"
          alt="phone"
          class="phone-icon"
          draggable="false"
        />
      </button>
    </div>
  `,
  styleUrls: ['./verify-phone.component.scss'],
  providers: [VerifyPhoneService],
})
export class VerifyPhoneComponent implements OnInit, OnDestroy {
  verified?: boolean;
  phoneIcon = 'assets/icons/phone.svg';

  private verifyPhoneSubscription$: Subscription;

  private modalManagerStore = useModalManagerStore.getState;

  constructor(private verifyPhoneService: VerifyPhoneService) {
    useUserStore.subscribe(
      (s) => s.phone_number_verified,
      (s) => {
        this.verified = s;
      }
    );
  }

  ngOnInit(): void {
    this.verified = useUserStore.getState().phone_number_verified;
  }

  onVerify = () => {
    const req$ = this.verifyPhoneService.sendVerifyCode();
    this.verifyPhoneSubscription$ = req$.pipe(take(1)).subscribe((res: any) => {
      if (res?.message !== 'Success') {
        this.modalManagerStore().toggleModal('phoneVerify', false);
        useToastStore.setState({
          isVisible: true,
          content: 'Opps looks like something went wrong, try that again',
          variant: 'danger',
        });
      }
    });
    this.verified = useUserStore.getState().phone_number_verified;
  };

  ngOnDestroy() {
    if (this.verifyPhoneSubscription$) {
      this.verifyPhoneSubscription$.unsubscribe();
    }
  }
}
