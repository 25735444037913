import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';

interface DisplayConfig {
  primaryText: string;   // Property name for the text to display
  secondaryText?: string; // Optional property name for the secondary text
  secondLineText?: string; // Optional property name for the second line text
  imageProperty?: string; // Optional property name for the image URL
}


@Component({
  selector: 'app-debouce-search-input',
  standalone: true,
  templateUrl: './debouce-search-input.component.html',
  imports: [ClickOutsideDirective, CommonModule, SharedModule, ReactiveFormsModule],
  styleUrls: ['./debouce-search-input.component.scss'],
})
export class DebouceSearchInputComponent {
  @Input() placeholder: string = 'Search...';
  @Input() theme: 'light' | 'dark' = 'light';
  @Input() label?: string;
  @Input() searchMethod: (searchString: string) => Observable<any>;
  @Input() displayConfig: DisplayConfig;  // Configuration object as an input
  @Input() listCategory?: string;
  @Input() formatResults: (item: any) => string = (item) => item.title || '';
  @Output() results = new EventEmitter<any[]>();
  @Output() selection = new EventEmitter<any>();
  searchForm: UntypedFormGroup;
  searchResults: any[] = [];
  isVisible = false;

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      searchString: new UntypedFormControl('')
    });

    this.searchForm.get('searchString').valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(searchString => {
        if (searchString && searchString.length > 2) {
          return this.searchMethod(searchString);
        } else {
          return []; // If the input is empty or too short, return an empty array
        }
      }),
      tap(response => {
        this.searchResults = response || [];
        this.isVisible = true; // Ensure visibility is set to true when there are results
        this.results.emit(this.searchResults);
      })
    ).subscribe();
  }

  onKeyup(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchForm.get('searchString')?.setValue(inputElement.value);
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.isVisible = false;
      this.clearResults();
    }
  }

  handleItemClick(item: any): void {
    this.selection.emit(item);
    this.searchResults = []; // Clear results after selection
    this.searchForm.get('searchString')?.setValue(''); // clear the search input
  }

  clearResults(): void {
    this.searchResults = [];
    this.searchForm.get('searchString')?.setValue('');
    this.isVisible = false; // Hide the results when cleared
  }

  // Helper method to get property values dynamically
  getProperty(item: any, propertyName: string): any {
    return item[propertyName];
  }
}
