import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipa-loading',
  template: `
  <div class="flex__column--center pipa-loading-container">
    <img src="assets/images/pipa_headshot.png" alt="loading" class="pipa-loading-img">
    <span class="s4">{{loadingText}}</span>
</div>
  `,
  styleUrls: ['./pipa-loading.component.scss']
})
export class PipaLoadingComponent implements OnInit {
  @Input() isLoading?: boolean;
  @Input() loadingText?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
