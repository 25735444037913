<app-modal [visible]="isVisible" [usePadding]="false">
  <div class="modal-wrapper flex__column">
    <div class="modal-header flex__center--space-between">
      <h5 class="modal-title">
        {{
          isSyncLeadListMode
            ? "Sync Lead Lists"
            : "Choose Campaign Filter Option"
        }}
      </h5>
      <img
        (click)="onCloseModalClick()"
        class="_close-icon"
        src="assets/icons/x-close-black.svg"
        alt="X"
        draggable="false"
      />
    </div>
    <span class="prompt-text">{{
      isSyncLeadListMode ? syncLeadlistPrompt : chooseFiltersPrompt
    }}</span>
    <app-tooltip
      [content]="learnMoreTooltip"
      [id]="'leadListLearnMore'"
      [tooltipPosition]="'right'"
    >
      <span class="learn-more-text"
        >Learn more about using Linkedin Lead Lists</span
      >
      <img
        class="info-icon"
        src="assets/icons/info.svg"
        alt="tooltip"
        draggable="false"
        style="margin-left: 0.5rem; width: 14px"
      />
    </app-tooltip>
    <div class="filter-actions-wrapper flex__column--space-evenly">
      <app-button
        *ngIf="!isSyncLeadListMode"
        (click)="onLinkedinLeadListClick()"
        [fullWidth]="true"
        class="light"
        label="Linkedin Lead List"
        style="margin-bottom: 0.35rem"
      ></app-button>
      <app-button
        *ngIf="!isSyncLeadListMode"
        (click)="onCustomFiltersClick()"
        [fullWidth]="true"
        class="light"
        label="Custom Filters"
      ></app-button>
      <app-button
        *ngIf="isSyncLeadListMode"
        [fullWidth]="true"
        [isLoading]="isLeadListSyncInProgress"
        [loadingLabel]="leadListLoadingText"
        label="Sync Linkedin Lead List"
        (click)="onSyncLinkedinLeadListClick()"
        [disabled]="isLeadListSyncInProgress"
        style="margin: 0 6px 0 2px"
      ></app-button>
    </div>
  </div>
</app-modal>
