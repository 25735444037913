import { Component, OnInit, OnDestroy } from '@angular/core';
import { helpContent } from 'src/app/constants/constants';
import { ResetPasswordService } from 'src/app/auth/reset-password/reset-password.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';

@Component({
  selector: 'reset-password-modal',
  template: `
    <app-modal [visible]="visible" [isDarkTheme]="true" [usePadding]="false">
      <div class="send-reset-wrapper">
        <div class="flex__row--space-between reset-pw-header-wrapper">
          <h5 class="send-reset-header flex__center">
            {{ success ? 'Success!' : 'Enter email for reset password link' }}
          </h5>
          <img
            (click)="onClose()"
            src="assets/icons/x-close.svg"
            alt="X"
            draggable="false"
            class="_close-icon close-btn"
          />
        </div>
        <input class="form-control email-input" (input)="onChange($event)" />
        <div class="alert alert-success" *ngIf="success">
          {{ resetPasswordPrompt }}
        </div>
        <div class="flex__center send-email-btn-wrapper">
          <!-- <app-button class="dismiss" id="dismiss-btn" (click)="onClose()" label="Dismiss"></app-button> -->
          <!-- <app-button
            style="border-radius: 50px; background: transparent; border-color: #fff;"
            id="dismiss-btn"
            (click)="onClose()"
            label="Dismiss"
          >
          </app-button> -->
          <button
            class="flex__center"
            id="reset-pw-btn"
            [disabled]="!emailAddress || loading || success"
            (click)="onSendEmailClick()"
          >
            {{ loading ? 'Loading...' : 'Send Email' }}
          </button>
        </div>
      </div>
    </app-modal>
  `,
  styleUrls: ['./reset-password-modal.component.scss'],
})
export class ResetPasswordModalComponent implements OnInit, OnDestroy {
  visible: boolean;
  emailAddress: string;
  loading: boolean;
  resetPasswordPrompt = helpContent.PASSWORD_RESET_SUCCESS;
  error: any;
  success = false;
  errorMessage: string;
  phoneNumber: string;

  constructor(private resetPasswordService: ResetPasswordService) {
    this.visible = useModalManagerStore.getState().resetPassword;
  }

  onClose() {
    this.success = false;
    useModalManagerStore.setState({ resetPassword: false });
  }

  onSendEmailClick() {
    // faking load because not handling errors here intentionally
    this.loading = true;
    this.resetPasswordService
      .sendResetPasswordEmail(this.emailAddress)
      .subscribe((res) => {
        this.success = true;
        this.loading = false;
        return res;
      });
    setTimeout(() => {
      this.onClose();
    }, 5000);
  }

  onChange(event) {
    this.emailAddress = event.target.value;
  }

  ngOnInit(): void {
    useModalManagerStore.subscribe(
      (s) => s.resetPassword,
      (newState) => {
        this.visible = newState;
      }
    );
  }

  ngOnDestroy(): void {
    useModalManagerStore.setState({ resetPassword: false });
  }
}
