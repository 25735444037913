<div *ngIf="!isSingleTemplate || hasChildren">
  <div *ngFor="let messageTemplate of messageTemplates">
    <div *ngFor="let key of objectKeys(messageTemplate)">
      <ng-container *ngIf="messageTemplate[key] && isValidMessageType(key)">
        <div class="message-template-cell-container">
          <div class="content-wrapper flex__column">
            <span class="name">{{ messageTemplate?.nickname }}</span>
            <span
              *ngIf="isSelectable"
              class="select-text"
              (click)="
                getMessageTemplateContent(
                  key,
                  messageTemplate[key],
                  messageTemplate?.nickname
                )
              "
              [ngStyle]="{
                color: selectedTemplates[messageTemplate?.nickname + '-' + key]
                  ? '#ed4343'
                  : '#635AFF'
              }"
            >
              {{
                selectedTemplates[messageTemplate?.nickname + "-" + key]
                  ? "Remove"
                  : "Select"
              }}
            </span>
            <div class="message-type">{{ getReadableMessageType(key) }}</div>
            <div
              class="message-content"
              [class.collapsed]="
                collapsedStates[messageTemplate?.nickname + '-' + key]
              "
              [innerHTML]="highlightVariable(messageTemplate[key])"
            ></div>
            <!-- <span
            (click)="toggleCollapse(messageTemplate?.nickname, key)"
            class="read-more-text"
          >
            {{
              collapsedStates[messageTemplate?.nickname + "-" + key]
                ? "Read More"
                : "Read Less"
            }}
          </span> -->
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="isSingleTemplate && !hasChildren" class="flex">
  <div class="flex__column">
    <img src="assets/images/pipa_headshot.png" style="width: 35px" />
    <span class="empty-templates-message s5"
      >It looks like <strong>{{ name }}</strong> doesn't have messages to
      show...</span
    >
    <button
      (click)="navigateToTemplate()"
      class="primary empty-templates-button"
    >
      <span class="empty-templates-text">Edit {{ name ? name : "" }}</span>
    </button>
  </div>
</div>
