<ng-select
  [items]="options"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [placeholder]="placeholder"
  [compareWith]="compareWithFn"
  [multiple]="isMultiple"
  [(ngModel)]="selectedItem"
  (clear)="clearSelected()"
  (change)="onChange($event)"
  appendTo="body"
>
</ng-select>
<!-- <p>Selected item: {{ selectedItem.id }}</p> -->
