import { createPipaStore } from './store.helpers';

interface AvailableModals {
  phoneVerify: boolean;
  resetPassword: boolean;
  associateTemplate: boolean;
  windowsWaitlist: boolean;
  campaignFilterOptions: boolean;
  isSyncLeadListMode: boolean;
  salesNavigatorCustomFiltersCampaign: boolean;
  salesNavigatorLeadListCampaign: boolean;
  premiumLinkedinCampaign: boolean;
  cloudCampaign: boolean;
  downloadCsv: boolean;
  campaignDailyLimit: boolean;
  activityLog: boolean;
  addLeadList: boolean;
  resetModalManagerStore: () => void;
}

interface ModalManagerState extends AvailableModals {
  toggleModal: (modalKey: keyof AvailableModals, isOpen: boolean) => void;
}

const initialState: AvailableModals = {
  phoneVerify: false,
  resetPassword: false,
  associateTemplate: false,
  windowsWaitlist: false,
  salesNavigatorCustomFiltersCampaign: false,
  salesNavigatorLeadListCampaign: false,
  premiumLinkedinCampaign: false,
  cloudCampaign: false,
  campaignFilterOptions: false,
  isSyncLeadListMode: false,
  downloadCsv: false,
  campaignDailyLimit: false,
  activityLog: false,
  addLeadList: false,
  resetModalManagerStore: () => null,
};

export const useModalManagerStore = (() =>
  createPipaStore<ModalManagerState>((set, get) => ({
    ...initialState,
    resetModalManagerStore: () => set({ ...initialState }),
    toggleModal: (modalKey, isOpen) => set({ [modalKey]: isOpen }),
  })))();
