export const LocalStorageKeys = {
  CLIENT_JWT: 'CLIENT_JWT',
  SETTINGS_ACCORDIAN: 'SETTINGS_ACCORDIAN',
  FILTERS_ACCORDIAN: 'FILTERS_ACCORDIAN',
  CONNECTIONS_ACCORDIAN: 'CONNECTIONS_ACCORDIAN',
  FILTER_BY_REPLIES: 'FILTER_BY_REPLIES',
};

export const SessionStorageKeys = {
  ACTIVE_CAMPAIGN_TYPE: 'ACTIVE_CAMPAIGN_TYPE',
  REDIRECT_URL: 'REDIRECT_URL',
};
