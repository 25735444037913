import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'chip',
  template: `
    <div class="flex chip-list-item {{ variant }} {{size}}">
      <img *ngIf="icon" class="flex tag-icon" src="{{ icon }}" alt="tags" class="tag-icon" />
      <div class="item-text">{{ text }}</div>
      <img
        src="{{ deleteIcon }}"
        alt="X"
        draggable="false"
        class="delete-icon"
        [ngStyle]="{
          'width': size === 'small' ? '20px' : '24px',
          'margin-left': size === 'small' ? '0.5rem' : '0.75rem'
        }"
        (click)="onDelete($event)"
        id="{{ id }}"
      />
    </div>
  `,
  styleUrls: ['./chip.component.scss'],
})
export class ChipComponent {
  @Input() text: string;
  @Input() id: any;
  @Input() variant?: 'primary' | 'secondary' = 'primary';
  @Output() onEmitDelete: EventEmitter<any> = new EventEmitter<any>();
  @Input() icon?: string;
  @Input() size?: 'small' | 'medium' = 'medium';
  blueTagsIcon = 'assets/icons/blue-tags.svg';
  deleteIcon = 'assets/icons/x-close.svg';

  constructor() { }

  onDelete = (e) => this.onEmitDelete.emit(e);
}
