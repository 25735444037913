import { ApiOperation } from "./helpers/api-operation";
import { createPipaStore } from "./store.helpers";


interface WaitlistState {
    addToWaitlist: ApiOperation
}

export const useWaitlistStore = (() => createPipaStore<WaitlistState>((set) => ({
    addToWaitlist: new ApiOperation('addToWaitlist', set)
})))()