import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-wrapper',
  template: `
  <div class="view__wrapper">
    <ng-content></ng-content>
  </div>
`,
  styleUrls: ['./view-wrapper.component.scss']
})
export class ViewWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
