import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appTextareaAutoresize]',
})
export class ResizableTextAreaDirective implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    @Optional() private control: NgControl,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.control) {
      const controlSub = this.control.valueChanges.subscribe(() => {
        this.cdr.detectChanges(); // Manually trigger change detection
        setTimeout(() => this.adjust(), 0); // Adjust after the DOM updates
      });
      this.subscription.add(controlSub);
    }

    setTimeout(() => this.adjust(), 0); // Initial adjustment
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  @HostListener('input', ['$event.target'])
  onInput(textArea: HTMLTextAreaElement): void {
    this.adjust();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    setTimeout(() => this.adjust(), 0);
  }

  private adjust(): void {
    const textArea = this.element.nativeElement;
    this.renderer.setStyle(textArea, 'overflow', 'hidden');
    this.renderer.setStyle(textArea, 'height', 'auto');
    const newHeight = textArea.scrollHeight;
    this.renderer.setStyle(textArea, 'height', `${newHeight}px`);
  }
}
