import { createPipaStore } from "./store.helpers";

export const Breakpoints = {
  desktop: 1280,
  tablet: 768,
  mobile: 568
}

enum Breakpoint {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile'
}

interface BreakpointState {
  currentBreakpoint: any,
  setCurrentBreakpoint: (breakpoint: boolean) => void
}

export const useBreakpointStore = (() => createPipaStore<BreakpointState>((set, get) => ({
  currentBreakpoint: () => {
    if (window.innerWidth >= Breakpoints.desktop) {
      return Breakpoint.desktop
    } else if (window.innerWidth >= Breakpoints.tablet && window.innerWidth < Breakpoints.desktop) {
      return Breakpoint.tablet
    } else {
      return Breakpoint.mobile
    }
  },
  setCurrentBreakpoint: () => set(state => state.currentBreakpoint())
})))()
