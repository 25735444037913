import { createAction, props } from '@ngrx/store';

// Action to add a profile to interactedProfiles
export const addInteractedProfile = createAction(
  '[Profile Activity] Add Interacted Profile',
  props<{ profile: any }>()
);

// Action to clear all interacted profiles
export const clearInteractedProfiles = createAction(
  '[Profile Activity] Clear Interacted Profiles'
);

// Action to navigate to the next profile
export const nextProfile = createAction(
  '[Profile Activity] Next Profile'
);

// Action to navigate to the previous profile
export const previousProfile = createAction(
  '[Profile Activity] Previous Profile'
);

// Action to start the connection invite process
export const startConnectionInviteProcess = createAction(
  '[Profile Activity] Start Connection Invite Process'
);

// Action to stop the connection invite process
export const stopConnectionInviteProcess = createAction(
  '[Profile Activity] Stop Connection Invite Process'
);

// Action to mark the process as completed
export const completeConnectionInviteProcess = createAction(
  '[Profile Activity] Complete Connection Invite Process'
);
