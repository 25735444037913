import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, tap, take, exhaustMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../user.model';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import { useUserStore } from '../../store/user.store';
import { UserProfileService } from 'src/app/user-profile/user-profile.service';
import { useToastStore } from 'src/app/store/toast.store';
import { LocalStorageKeys } from 'src/app/constants/storageKeys';
import { VerifyPhoneService } from 'src/app/services/verify-phone-service/verify-phone.api.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { AuthService, SignInCredentials } from '../auth-service.service';
import { helpContent } from 'src/app/constants/constants';
import * as AuthActions from './auth.actions';

const handleSignUpSuccess = (userCreds: SignInCredentials) => {
  return AuthActions.loginStart(userCreds);
};

const handleError = (errorRes: any) => {
  localStorage.removeItem(LocalStorageKeys.CLIENT_JWT);
  let errorMessage = helpContent.GENERAL_ERROR;
  if (!errorRes.status || !errorRes.error) {
    useToastStore.setState({
      isVisible: true,
      content: `${errorMessage}`,
      variant: 'danger',
    });
    // of() is a utility function for creating a new obserable without an error
    return of(AuthActions.authenticateFail(errorMessage));
  }

  switch (errorRes.status) {
    case 403:
      errorMessage = 'Client is not authorized to access this resource.';
      break;
    case 401:
      errorMessage = 'Email or password is incorrect.';
      break;
    case 409:
      errorMessage = 'Email already exists';
      break;
    default:
      errorMessage;
  }
  useToastStore.setState({
    isVisible: true,
    content: `${errorMessage}`,
    variant: 'danger',
  });
  return of(AuthActions.authenticateFail(errorMessage));
};

@Injectable()
export class AuthEffects {
  private setUser = useUserStore.getState().setUser;
  private resetUser = useUserStore.getState().resetUser;
  private isPhoneVerified: boolean;
  private verifyPhoneSubscription$: Subscription;
  private modalManagerStore = useModalManagerStore.getState;

  authSignup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.signUpStart),
      exhaustMap((action) =>
        this.authService.authSignUp(action).pipe(
          tap((resData) => {
            this.setUser(resData);
          }),
          map((resData: any) => {
            return handleSignUpSuccess({
              email: action.email,
              password: action.password,
            });
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        )
      )
    )
  );

  authLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginStart),
      exhaustMap((action) =>
        this.authService.authLogin(action).pipe(
          tap((resData) => {
            // this.setUser(resData);
          }),
          map((resData: any) => {
            return this.handleAuthentication(resData.access_token);
          }),
          catchError((errorRes) => {
            return handleError(errorRes);
          })
        )
      )
    )
  );

  autoLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.autoLogin),
      map(() => {
        const userData: {
          _token: string;
        } = JSON.parse(localStorage.getItem(LocalStorageKeys.CLIENT_JWT));
        if (!userData) {
          return { type: 'NO_USER_DATA' };
        }
        if (this.requestHelpers.isJwtExpired()) {
          localStorage.removeItem(LocalStorageKeys.CLIENT_JWT);
          this.router.navigate(['/auth']);
        } else {
          this.handleAuthentication(userData._token);
          return AuthActions.authenticateSuccess({
            token: userData._token,
            redirect: false,
          });
        }
        return { type: 'USER' };
      })
    )
  );

  autoLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        tap(() => {
          this.resetUser();
          localStorage.removeItem(LocalStorageKeys.CLIENT_JWT);
          this.router.navigate(['/auth']);
        })
      ),
    { dispatch: false }
  );

  authRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.authenticateSuccess),
        tap((authSuccessAction) => {
          if (authSuccessAction.redirect) {
            if (this.router.routerState.snapshot.url.includes('registration'))
              this.router.navigate(['/billing-plans']);
            else this.router.navigate(['/']);
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private requestHelpers: RequestHelpersService,
    private userService: UserProfileService,
    private verifyPhoneService: VerifyPhoneService,
    private authService: AuthService
  ) { }

  handleAuthentication = (token: string) => {
    const asyncFunc = async () => {
      localStorage.removeItem(LocalStorageKeys.CLIENT_JWT);
      const user = new User(token);
      localStorage.setItem(LocalStorageKeys.CLIENT_JWT, JSON.stringify(user));
      const userProfile = await this.userService.getUserProfile();
      useUserStore.setState({ ...userProfile });
      if (token) this.openPhoneVerifyModal();
    };

    asyncFunc();

    return AuthActions.authenticateSuccess({
      token: token,
      redirect: true,
    });
  };

  openPhoneVerifyModal = () => {
    this.userService.getUserProfile();
    const isPhoneVerified = useUserStore.getState().phone_number_verified;
    this.isPhoneVerified = isPhoneVerified;
    if (!isPhoneVerified) {
      const phoneVerifySub$ = this.verifyPhoneService.sendVerifyCode();
      phoneVerifySub$.pipe(take(1)).subscribe((res: any) => {
        if (res?.message !== 'Success') {
          this.modalManagerStore().toggleModal('phoneVerify', false);
        }
      });
    }
  };
}
