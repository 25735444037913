import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'any'
})
export class OnboardingHelperService {

  constructor(
    private route: ActivatedRoute,
  ) { }

  isNewUser() {
    if (this.route.snapshot.queryParams?.showCampaignTour) {
      return true;
    }
  }

  isNewUserCampaignFlow() {
    if (this.route.snapshot.queryParams?.withCreateTemplate) {
      return true;
    }
  }

  isNewUserCreateTemplateFlow() {
    if (this.route.snapshot.queryParams?.cid) {
      return true;
    }
  }

  isRedirectToCampaign() {
    if (this.route.snapshot.queryParams?.cid && this.route.snapshot.queryParams?.redirect) {
      return true;
    }
  }

  getAttachedCampaignId() {
    return +this.route.snapshot.queryParams?.cid;
  }

}
