import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch',
  template: `
    <div title="{{ title }}" class="form-check form-switch flex__center">
      <input
        mdbCheckbox
        class="form-check-input {{ size }}"
        type="checkbox"
        id="{{ id }}"
        [checked]="checked"
        [disabled]="disabled"
      />
      <span *ngIf="label" class="form-check-label" for="{{ id }}">{{
        label
      }}</span>
    </div>
  `,
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() id: string;
  @Input() title: string;
  @Input() size?: 'small' | 'medium' | 'large' = 'small';

  constructor() {}

  ngOnInit(): void {}
}
