import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from '../auth/store/auth.reducer';
import * as fromCampaigns from '../campaigns/store/campaigns.reducer';
import * as fromMessageTemplates from '../message-templates/store/message-templates.reducer';
import * as fromUser from '../store/user/user.reducer';
import * as fromLeadList from '../store/lead-list/lead-list.reducer';
import * as fromProfileActivity from '../store/profile-activity/profile-activity.reducer';

export interface AppState {
  auth: fromAuth.State;
  campaigns: fromCampaigns.State;
  messageTemplates: fromMessageTemplates.State;
  user: typeof fromUser.initialState;
  leadLists: typeof fromLeadList.initialState;
  profileActivity: typeof fromProfileActivity.initialState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  campaigns: fromCampaigns.campaignsReducer,
  messageTemplates: fromMessageTemplates.messageTemplatesReducer,
  user: fromUser.userReducer,
  leadLists: fromLeadList.leadListReducer,
  profileActivity: fromProfileActivity.profileActivityReducer,
}
