import { createAction, props } from '@ngrx/store';
import { UserState } from './user.interface';
import { PipaProduct } from 'src/app/services/products-service/PipaProduct';

export const FETCH_USER_DETAILS_START = '[User] Fetch User Details Start';
export const FETCH_USER_DETAILS_SUCCESS = '[User] Fetch User Details Success';
export const FETCH_USER_DETAILS_FAIL = '[User] Fetch User Details Fail';

// Action to initiate fetching user details
export const fetchUserDetailsStart = createAction(
  FETCH_USER_DETAILS_START
);

// Action for successfully fetching user details
export const fetchUserDetailsSuccess = createAction(
  FETCH_USER_DETAILS_SUCCESS,
  props<{
    user: Partial<UserState> & { Product?: PipaProduct };
  }>()
);

// Action for failing to fetch user details
export const fetchUserDetailsFail = createAction(
  FETCH_USER_DETAILS_FAIL,
  props<{ error: string }>()
);
