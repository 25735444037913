<app-modal [visible]="isVisible" [usePadding]="false">
  <div class="modal-wrapper flex__column">
    <div class="modal-header flex__center--space-between">
      <h5 class="modal-title">Download CSV</h5>
      <img
        (click)="onCloseModalClick()"
        class="_close-icon"
        src="assets/icons/x-close-black.svg"
        alt="X"
        draggable="false"
      />
    </div>
    <span class="prompt-text"
      >Export <strong>{{ campaignName }}.csv</strong> with your prospects data
      for this campaign.</span
    >
    <div class="download-csv-actions-wrapper flex__column--space-evenly">
      <app-button
        (click)="onDownloadCsvClick()"
        [fullWidth]="true"
        class="secondary"
        label="Download {{ campaignName }}.csv"
      ></app-button>
    </div>
  </div>
</app-modal>
