import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'action-bar',
  template: `
    <div class="flex__center action-bar-wrapper" [id]="id">
      <div *ngIf="useCustom" class="custom-content">
        <ng-content select="[customActions]"></ng-content>
      </div>
      <app-button
        *ngIf="useDelete"
        class="danger action-btn"
        (click)="onDelete()"
        label="Delete"
      >
      </app-button>
      <app-button
        [isLoading]="isCustomBtnLoading"
        [loadingLabel]="customBtnLoadingLabel"
        *ngIf="useCustomButton"
        label="{{ customButtonLabel }}"
        class="{{ customVariant }} action-btn"
        (click)="onCustomClick()"
        [disabled]="isCustomBtnDisabled"
        style="margin: 0 6px 0 2px"
      >
      </app-button>
      <button
        *ngIf="useEdit"
        class="edit-btn action-btn flex__center"
        (click)="onEditClick()"
        [ngStyle]="{
          'background-color': !editVariant && '#0A2540;'
        }"
      >
        {{ editLabel }}
      </button>
      <app-button
        *ngIf="useSubmit"
        id="action-bar-submit"
        class="primary action-btn"
        label="{{ actionLabel }}"
        (click)="onSubmit()"
        [disabled]="isDisabled"
      >
      </app-button>
      <img
        *ngIf="useCancel"
        (click)="onCancel()"
        src="assets/icons/x-close-gray.svg"
        alt="X"
        draggable="false"
        class="_close-icon"
      />
    </div>
  `,
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent {
  @Output() onEmitDelete = new EventEmitter();
  @Output() onEmitCancel = new EventEmitter();
  @Output() onEmitSubmit = new EventEmitter();
  @Output() onEmitCustomClick = new EventEmitter();
  @Output() onEmitEditClick = new EventEmitter();
  @Input() id?: string;
  @Input() isDisabled = false;
  @Input() actionLabel = 'Save';
  @Input() useDelete = false;
  @Input() useCancel = true;
  @Input() useSubmit: boolean = true;

  @Input() useCustom = false;
  @Input() useCustomButton = false;
  @Input() customBtnLoadingLabel: string;
  @Input() isCustomBtnLoading = false;
  @Input() customButtonLabel: string = 'Custom';
  @Input() customVariant: string = 'light';
  @Input() isCustomBtnDisabled = false;

  @Input() editVariant: string;
  @Input() editLabel: string = 'Edit';
  @Input() useEdit = false;

  onDelete = () => this.onEmitDelete.emit();
  onCancel = () => this.onEmitCancel.emit();
  onSubmit = () => this.onEmitSubmit.emit();
  onCustomClick = () => this.onEmitCustomClick.emit();
  onEditClick = () => this.onEmitEditClick.emit();

  constructor() {}
}
