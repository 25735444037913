<div class="global_container">
  <app-header *ngIf="isAuthenticated && activeRoute !== 'auth'"></app-header>
  <div class="main_content_wrapper">
    <app-side-nav
      *ngIf="isAuthenticated && activeRoute !== 'auth'"
    ></app-side-nav>
    <router-outlet></router-outlet>
  </div>
  <app-verify-phone-modal></app-verify-phone-modal>
  <app-windows-waitlist-modal></app-windows-waitlist-modal>
  <app-toast></app-toast>
</div>
