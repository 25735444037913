import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-activity-stats-nugget',
  templateUrl: './activity-stats-nugget.component.html',
  styleUrl: './activity-stats-nugget.component.scss'
})
export class ActivityStatsNuggetComponent {
  @Input() label: string;
  @Input() value: number;
  @Input() isLoading?: boolean;
  @Input() theme?: 'neutral' | 'light' | 'dark' = 'neutral';


}
