import { Component, Input, OnInit } from '@angular/core';
import { useBreakpointStore } from 'src/app/store/breakpoint.store';

@Component({
  selector: 'app-page-header',
  template: `
    <div class="page_header_wrapper">
      <span *ngIf="!isMobile && title" class="page_header s4">{{ title }}</span>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input() title: string;

  isMobile: boolean = false;
  getScreenWidth: any;

  constructor() {}

  isMobileScreen = () =>
    (this.isMobile =
      useBreakpointStore.getState().currentBreakpoint() === 'mobile');

  ngOnInit(): void {
    this.isMobileScreen();
    window.addEventListener(
      'resize',
      (e) => {
        e.preventDefault();
        this.isMobileScreen();
      },
      { passive: true }
    );
  }
}
