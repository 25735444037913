import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'any'
})
export class RequestHelpersService {
  headers: HttpHeaders;
  private jwtHelper = new JwtHelperService();

  constructor() { }

  getJwt = () => JSON.parse(localStorage.getItem('CLIENT_JWT'))?._token || '';

  createHeaders = (withoutAuth?: boolean) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    if (withoutAuth) return headers;
    headers['Authorization'] = `Bearer ${this.getJwt()}`;
    this.headers = new HttpHeaders(headers);
    return this.headers;
  }

  getClientId = () => this.jwtHelper.decodeToken(this.getJwt()).uuid;

  isJwtExpired = () => this.jwtHelper.isTokenExpired(this.getJwt());
}
