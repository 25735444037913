import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { LeadListService } from 'src/app/leads/services/lead-list.service';
import * as LeadListActions from './lead-list.actions';

@Injectable()
export class LeadListEffects {
  constructor(private actions$: Actions, private leadListService: LeadListService) { }

  fetchLeadLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadListActions.fetchLeadListsStart),
      mergeMap(() =>
        this.leadListService.getLeadLists().pipe(
          map((leadLists: any) => LeadListActions.fetchLeadListsSuccess({ leadLists })),
          catchError((error) => of(LeadListActions.fetchLeadListsFail({ error })))
        )
      )
    )
  );

  addLeadList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LeadListActions.addLeadListStart),
      mergeMap((action) =>
        this.leadListService.createLeadList(action.leadList).pipe(
          map((leadList: any) => LeadListActions.addLeadListSuccess({ leadList })),
          catchError((error) => of(LeadListActions.addLeadListFail({ error })))
        )
      )
    )
  );
}
