import { MessageTemplate } from "../message-template.model";
import { createAction, props } from "@ngrx/store";

export const GET_MESSAGE_TEMPLATES = "[Message Templates] Get Message Templates";
export const GET_MESSAGE_TEMPLATES_SUCCESS = "[Message Templates] Get Message Templates Success";
export const GET_MESSAGE_TEMPLATES_FAILURE = "[Message Templates] Get Message Templates Failure";

export const ADD_MESSAGE_TEMPLATE = "[Message Templates] Add Message Template";
export const ADD_MESSAGE_TEMPLATE_SUCCESS = "[Message Templates] Add Message Template Success";
export const ADD_MESSAGE_TEMPLATE_FAILURE = "[Message Templates] Add Message Template Failure";

export const UPDATE_MESSAGE_TEMPLATE = "[Message Templates] Update Message Template";
export const UPDATE_MESSAGE_TEMPLATE_SUCCESS = "[Message Templates] Update Message Template Success";
export const UPDATE_MESSAGE_TEMPLATE_FAILURE = "[Message Templates] Update Message Template Failure";

export const DELETE_MESSAGE_TEMPLATE = "[Message Templates] Delete Message Template";
export const DELETE_MESSAGE_TEMPLATE_SUCCESS = "[Message Templates] Delete Message Template Success";
export const DELETE_MESSAGE_TEMPLATE_FAILURE = "[Message Templates] Delete Message Template Failure";

export const getMessageTemplates = createAction(
    GET_MESSAGE_TEMPLATES,
);

export const getMessageTemplatesSuccess = createAction(
    GET_MESSAGE_TEMPLATES_SUCCESS,
    props<{ payload: MessageTemplate[] }>()
);

export const getMessageTemplatesFailure = createAction(
    GET_MESSAGE_TEMPLATES_FAILURE,
    props<{ error: any }>()
);

export const addMessageTemplate = createAction(
    ADD_MESSAGE_TEMPLATE,
    props<any>()
);

export const addMessageTemplateSuccess = createAction(
    ADD_MESSAGE_TEMPLATE_SUCCESS,
    props<any>()
);

export const addMessageTemplateFailure = createAction(
    ADD_MESSAGE_TEMPLATE_FAILURE,
    props<{ error: any }>()
);

export const updateMessageTemplate = createAction(
    UPDATE_MESSAGE_TEMPLATE,
    props<any>()
);

export const updateMessageTemplateSuccess = createAction(
    UPDATE_MESSAGE_TEMPLATE_SUCCESS,
    props<any>()
);

export const updateMessageTemplateFailure = createAction(
    UPDATE_MESSAGE_TEMPLATE_FAILURE,
    props<{ error: any }>()
);

export const deleteMessageTemplate = createAction(
    DELETE_MESSAGE_TEMPLATE,
    props<any>()
);

export const deleteMessageTemplateSuccess = createAction(
    DELETE_MESSAGE_TEMPLATE_SUCCESS,
    props<any>()
);

export const deleteMessageTemplateFailure = createAction(
    DELETE_MESSAGE_TEMPLATE_FAILURE,
    props<{ error: any }>()
);
