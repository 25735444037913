<div
  class="account_menu_wrapper"
  (click)="toggleDropdown()"
  (clickOutside)="dropdownOpen = false"
>
  <div class="account_menu_icon">
    <profile-image
      size="small"
      [image]="user.ClientLinkedinMetaDatum?.profile_image"
      [fullName]="user.full_name"
    ></profile-image>
  </div>
  <div class="{{ dropdownOpen ? 'dropdown_list' : 'drop_down_inactive' }}">
    <li class="menu-item user-name">{{ user.full_name }}</li>
    <li class="menu-item" (click)="navigateTo('user-profile')">
      Account Details
    </li>
    <li class="menu-item" (click)="onLogout()">Logout</li>
  </div>
</div>
