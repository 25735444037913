import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { VerifyPhoneModalComponent } from './verify-phone-modal/verify-phone-modal.component';
import { ResetPasswordModalComponent } from './reset-password-modal/reset-password-modal.component';
import { TemplatesModalComponent } from './templates-modal/templates-modal.component';
import { EditTemplatesFormComponent } from './templates-modal/edit-templates-form/edit-templates-form.component';
import { WindowsWaitlistModalComponent } from './windows-waitlist-modal/windows-waitlist-modal.component';
import { CampaignFilterOptionsComponent } from './campaign-filter-options/campaign-filter-options.component';
import { DownloadCsvComponent } from './download-csv/download-csv.component';
import { TooltipComponent } from '../shared/tooltip/tooltip.component';
import { DropdownSelectComponent } from '../shared/dropdown-select/dropdown-select.component';
import { CampaignDailyLimitModalComponent } from './campaign-daily-limit-modal/campaign-daily-limit-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivityLogModalComponent } from './activity-log-modal/activity-log-modal.component';

@NgModule({
  declarations: [
    WindowsWaitlistModalComponent,
    VerifyPhoneModalComponent,
    ResetPasswordModalComponent,
    TemplatesModalComponent,
    EditTemplatesFormComponent,
    CampaignFilterOptionsComponent,
    DownloadCsvComponent,
    CampaignDailyLimitModalComponent,
    ActivityLogModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipComponent,
    DropdownSelectComponent,
  ],
  exports: [
    VerifyPhoneModalComponent,
    ResetPasswordModalComponent,
    TemplatesModalComponent,
    WindowsWaitlistModalComponent,
    CampaignFilterOptionsComponent,
    DownloadCsvComponent,
    CampaignDailyLimitModalComponent,
    ActivityLogModalComponent,
  ],
})
export class ModalsModule { }
