import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { UserProfileService } from 'src/app/services/user-profile.service';
import * as UserActions from './user.actions';


@Injectable()
export class UserEffects {
  constructor(private actions$: Actions, private userService: UserProfileService) { }

  // Effect to fetch user details
  fetchUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUserDetailsStart),
      mergeMap((action) =>
        this.userService.getUserProfile().pipe(
          map((user) =>
            UserActions.fetchUserDetailsSuccess({ user })
          ),
          catchError((error) => of(UserActions.fetchUserDetailsFail({ error })))
        )
      )
    )
  );
}
