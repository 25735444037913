import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import { HttpClient } from '@angular/common/http';
import { catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {
  private url: string = `${environment.apiUrl}/auth/reset-password`;
  private headers = this.requestHelpers.createHeaders();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService,
  ) { }

  sendResetPasswordEmail(email: string) {
    return this.http.post(`${this.url}/email`, { email }, { headers: this.headers }).pipe(catchError(async (e) => this.handleResetPasswordEmailError(e)));
  }

  resetPassword(user_id: string, token: string, password: string) {
    return this.http.post(`${this.url}`, { user_id, token, password }, { headers: this.headers }).pipe(catchError(async (e) => this.handleResetPasswordError(e)));
  }

  private handleResetPasswordEmailError(err: any) {
    if (err.status === 404) {
      return err.status;
    } else if (err.status === 429) {
      return err.status;
    } else {
      console.error(`An unknown error has occurred: `, err.status);
      return new Error(err.status);
    }
  }

  private handleResetPasswordError = (err: any) => of(new Error(err));
}
