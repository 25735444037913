<div class="profile-vertical-card-container _easeTopToBottom">
  <div class="profile-details-wrapper flex__column--center">
    <profile-image [image]="image" [fullName]="fullName"></profile-image>
    <div class="profile-meta-wrapper flex__column--center">
      <span class="profile-name">{{ fullName }}</span>
      <span class="main-detail job-title">{{ jobTitle }}</span>
      <span *ngIf="!!company" class="main-detail company">{{ company }}</span>
      <div *ngIf="!!connectedOn" class="connected-on">
        <span class="connected-on-label">Connected: </span>
        <span class="connected-on-date">{{ connectedOn }}</span>
      </div>
      <div class="flex__column--center">
      <div *ngIf="!!location" class="profile-metadata flex location">
        <img
          src="assets/icons/location-pin.svg"
          class="profile-icon"
          alt="location-icon"
          draggable="false"
        />
        <span href="" class="profile-metadata-text">{{ location }}</span>
      </div>
      <div *ngIf="!!email" class="profile-metadata flex email">
        <img
          src="assets/icons/envelope.svg"
          class="profile-icon"
          alt="email-icon"
          draggable="false"
        />
        <a
          href="mailto:{{ email }}"
          target="_blank"
          class="profile-metadata-text link"
          >{{ email }}</a
        >
      </div>
      <div *ngIf="!!phone" class="profile-metadata flex">
        <img
          src="assets/icons/phone-outline.svg"
          class="profile-icon"
          alt="phone-icon"
          draggable="false"
        />
        <a
          href="tel:{{ phone }}"
          target="_blank"
          class="profile-metadata-text link"
          >{{ formatPhoneNumber(phone) }}</a
        >
      </div>
    </div>
    </div>
  </div>
</div>
