import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, tap } from "rxjs";
import { RequestHelpersService } from "src/app/shared/services/request-helpers.service";
import { useModalManagerStore } from "src/app/store/modal-manager.store";
import { useWaitlistStore } from "src/app/store/waitlist.store";
import { environment } from "src/environments/environment";

interface WaitlistEntry {
    email: string;
    name?: string;
    waitlist_name?: string;
}

@Injectable({
    providedIn: 'root'
})
export class WaitlistService {
    private createHeaders = () => this.requestHelpers.createHeaders();
    private baseUrl: string = `${environment.apiUrl}/waitlist`;

    private waitlistStore = useWaitlistStore.getState
    private modalManagerStore = useModalManagerStore.getState

    constructor(private http: HttpClient, private requestHelpers: RequestHelpersService) { }

    addToWaitlist = (waitlistEntry: WaitlistEntry) => {
        this.waitlistStore().addToWaitlist.startLoading()
        const request$ = this.http.post(this.baseUrl, waitlistEntry, { headers: this.createHeaders() })
            .pipe(
                tap(resData => {
                    if (!resData.hasOwnProperty('errors')) {
                        this.waitlistStore().addToWaitlist.succeeded()
                        setTimeout(() => {
                            this.modalManagerStore().toggleModal('windowsWaitlist', false)}, 3000)
                    }
                }),
                catchError(errorRes => {
                    this.waitlistStore().addToWaitlist.failed(errorRes)
                    return errorRes
                })
            )
        return request$
    }
}