import { Campaign } from '../campaigns/campaigns.model';
import { createPipaStore } from './store.helpers';
type PartialCampaign = Partial<Campaign>
interface CampaignsStoreV2 {
    newCampaign?: PartialCampaign
    newCampaignError?: any
    setNewCampaign: (newCampaign: PartialCampaign) => void
}

export const useCampaignStore = (() => createPipaStore<CampaignsStoreV2>((set, get) => ({
    newCampaign: null,
    newCampaignError: null,
    setNewCampaign: (newCampaign) => set({ newCampaign }),
})))()
