import { ApiOperation } from './helpers/api-operation';
import { createPipaStore } from './store.helpers';

interface ToastState {
  isVisible: boolean
  content: string
  variant?: 'success' | 'danger'
  delay?: number
}

export const useToastStore = (() => createPipaStore<ToastState>((set, get) => ({
  isVisible: false,
  content: '',
  variant: 'success',
  delay: 5000,
  hide: () => set({ isVisible: false })
})))()
