import { Component, Input, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { PipaTourService } from '../services/pipa-tour.service';

@Component({
  selector: 'app-tooltip',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  template: `
    <div id="{{ id }}" (click)="onClick()" class="tooltip-wrapper">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() content: string;
  @Input() id: string;
  @Input() tooltipPosition?: string = 'bottom';

  constructor(private pipaTourService: PipaTourService) {}

  onClick() {
    this.pipaTourService.showTooltip(
      `#${this.id}`,
      this.content,
      this.tooltipPosition
    );
  }
}
