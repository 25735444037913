<div class="card-list-item" [ngClass]="{ 'card-list-item__active': isActive }">
  <div class="card-list-item__content">
    <div class="card-list-item__content-wrapper">
      <div
        class="card-list-item__primary-text-wrapper"
        [ngClass]="{ 'flex__row--space-between': !!actionText }"
      >
        <span *ngIf="primaryText" class="card-list-item__primary-text">{{
          primaryText
        }}</span>
        <app-button
          *ngIf="actionText"
          [label]="actionText"
          (click)="onActionClick.emit($event)"
          class="text-neutral"
        >
        </app-button>
      </div>
      <span *ngIf="secondaryText" class="card-list-item__secondary-text">{{
        secondaryText
      }}</span>
      <ng-content></ng-content>
    </div>
  </div>
</div>
