import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-choice-button-group',
  templateUrl: './choice-button-group.component.html',
  styleUrls: ['./choice-button-group.component.scss']
})
export class ChoiceButtonGroupComponent implements OnInit, OnChanges {
  @Input() options: string[] = []; // Options to display
  @Input() multiple: boolean = false; // Determine if multiple selections are allowed
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() defaultSelectedOptions: string[] = [];
  @Input() disabled: boolean = false;
  @Output() selectionChange = new EventEmitter<string[]>();

  selectedOptions: Set<string> = new Set();

  ngOnInit(): void {
    this.updateSelectedOptions();
  }

  // Trigger this when the inputs change
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultSelectedOptions) {
      this.updateSelectedOptions();
    }
  }


  toggleOption(option: string): void {
    if (this.multiple) {
      if (this.selectedOptions.has(option)) {
        this.selectedOptions.delete(option);
      } else {
        this.selectedOptions.add(option);
      }
    } else {
      this.selectedOptions.clear();
      this.selectedOptions.add(option);
    }
    this.selectionChange.emit(Array.from(this.selectedOptions));
  }

  updateSelectedOptions(): void {
    this.selectedOptions = new Set(this.defaultSelectedOptions);
    this.selectionChange.emit(Array.from(this.selectedOptions));
  }


  isOptionSelected(option: string): boolean {
    return this.selectedOptions.has(option);
  }
}
