import { createFeatureSelector, createReducer, on } from "@ngrx/store";
import { MessageTemplate } from "src/app/message-templates/message-template.model";
import * as MessageTemplatesActions from "../../store/message-templates/message-templates.actions";
import * as fromRoot from "../../store/app.reducer";

export interface State {
  messageTemplates: MessageTemplate[];
  isLoading?: boolean;
  isLoadingSuccess?: boolean;
  isLoadingFailure?: boolean;
  error?: any;
}

const initialState: State = {
  messageTemplates: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null,
};

export const messageTemplatesReducer = createReducer(
  initialState,
  on(MessageTemplatesActions.getMessageTemplates, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.getMessageTemplatesSuccess, (state, { payload }) => ({
    ...state,
    messageTemplates: payload,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.getMessageTemplatesFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),
  on(MessageTemplatesActions.addMessageTemplate, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.addMessageTemplateSuccess, (state, { payload }) => ({
    ...state,
    messageTemplates: [...state.messageTemplates, payload],
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.addMessageTemplateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),
  on(MessageTemplatesActions.updateMessageTemplate, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.updateMessageTemplateSuccess, (state, { payload }) => ({
    ...state,
    messageTemplates: state.messageTemplates.map(messageTemplate => {
      if (messageTemplate.id === payload.id) {
        return payload;
      }
      return messageTemplate;
    }
    ),
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.updateMessageTemplateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),
  on(MessageTemplatesActions.deleteMessageTemplate, state => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.deleteMessageTemplateSuccess, (state, { payload }) => ({
    ...state,
    messageTemplates: state.messageTemplates.filter(messageTemplate => messageTemplate.id !== payload),
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null
  })),
  on(MessageTemplatesActions.deleteMessageTemplateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error: error
  })),
  on(MessageTemplatesActions.resetMessageTemplates, () => ({
    ...initialState
  }))

);

export const messageTemplatesFeatureKey = "messageTemplates";

export const messageTemplatesFeatureState = createFeatureSelector<fromRoot.AppState>(messageTemplatesFeatureKey);

