import { trigger, transition, style, animate } from '@angular/animations';

export const slideUpDown = trigger('slideUpDown', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }), // Start from bottom
    animate('100ms ease-in', style({ transform: 'translateY(0)' })) // Slide up
  ]),
  transition(':leave', [
    animate('100ms ease-out', style({ transform: 'translateY(100%)' })) // Slide down
  ])
]);
