<div
  class="menu-wrapper"
  (click)="toggleMenu()"
  (clickOutside)="dropdownOpen = false"
>
  <div class="menu-trigger">
    <ng-content></ng-content>
  </div>
  <div [class]="dropdownOpen ? 'menu-list' : 'menu-inactive'">
    <li
      *ngFor="let label of labels"
      class="menu-item clickable"
      (click)="handleClick(label)"
    >
      {{ label }}
    </li>
  </div>
</div>
