import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { useTemplatesStore } from 'src/app/store/templates.store';
import { campaignsFeatureKey } from 'src/app/campaigns/store/campaigns.reducer';
import { messageTemplatesFeatureKey } from 'src/app/message-templates/store/message-templates.reducer';
import { useUserStore } from 'src/app/store/user.store';

import * as MessageTemplatesActions from '../../../message-templates/store/message-templates.actions';
import * as fromRoot from '../../../store/app.reducer';

@Component({
  selector: 'edit-templates-form',
  template: `
  <div>
    <div [formGroup]="templateForm" *ngIf="messageTemplateId">
    <message-template-options
      *ngIf="(showDripInput || showMessageBeforeConnectionInput || showMessageAfterConnectionInput)"
      [isProfessional]="isProfessional"
      [addMode]="false"
      [campaignId]="campaignId"
      [isDripCampaign]="isDripCampaign"
      [isConnectWithMessage]="isMessageBeforeConnection"
      [isMessageAfterConnect]="isMessageAfterConnection">
    </message-template-options>
      <app-text-area
        *ngIf="showMessageBeforeConnectionInput"
        (change)="onChange('message_before_connection', $event)"
        [value]="templateForm.controls['message_before_connection'].value"
        [isError]="(isError('message_before_connection')) || (hasExceededMaxCharacters(messageBeforeConnectLen))"
        [label]="messageBeforeConnectLabel"
        errorMessage="{{messageBeforeConnectError()}}"
        name="message_before_connection"
        id="message_before_connection"
        formControlName="message_before_connection">
      </app-text-area>
      <app-text-area
        *ngIf="showMessageAfterConnectionInput"
        (change)="onChange('first_interaction', $event)"
        [value]="templateForm.controls['first_interaction'].value"
        [isError]="isError('first_interaction')"
        [errorMessage]="'Send message after connection' + errorMessage"
        label="Message after connection"
        name="first_interaction"
        id="first_interaction"
        formControlName="first_interaction">
      </app-text-area>
      <app-text-area
        *ngIf="showDripInput"
        (change)="onChange('first_drip', $event)"
        [value]="templateForm.controls['first_drip'].value"
        [isError]="isError('first_drip')"
        [errorMessage]="'Include drip campaign' + errorMessage"
        label="First drip message"
        name="first_drip"
        id="first_drip"
        formControlName="first_drip">
      </app-text-area>
      <app-text-area
        *ngIf="showDripInput"
        (change)="onChange('second_drip', $event)"
        [value]="templateForm.controls['second_drip'].value"
        label="Second drip message (optional)"
        name="second_drip" id="second_drip"
        formControlName="second_drip">
      </app-text-area>
      <app-text-area
        *ngIf="showDripInput"
        (change)="onChange('final_drip', $event)"
        [value]="templateForm.controls['final_drip'].value"
        label="Final drip message (optional)"
        name="final_drip" id="final_drip"
        formControlName="final_drip">
      </app-text-area>
    </div>
    <div class="flex__row--end message-template-actions">
      <app-button *ngIf="messageTemplates.length" [disabled]="isDisabled()" class="primary" (click)="onSave()" label="Activate Campaign"></app-button>
      <app-button toRoute="{{'/message-templates/add-message-template'}}" *ngIf="!messageTemplates.length" class="primary" label="Add Message Template"></app-button>
    </div>
    </div>
  `,
  styleUrls: ['./edit-templates-form.component.scss'],
})

export class EditTemplatesFormComponent implements OnDestroy {
  @Input() isVisible: boolean = false;
  @Input() messageTemplateId: any;
  @Input() isComplete: boolean = false;
  @Input() messageTemplates: any;
  @Output() onSaveTemplateClick = new EventEmitter<any>();
  campaignId: any;
  campaignName: any;
  messageBeforeConnection: any;
  messageBeforeConnectLen: number;
  messageAfterConnectLen: number;
  firstDripLen: number;
  messageBeforeConnectLabel: any;
  isMessageBeforeConnection: boolean;
  isMessageAfterConnection: boolean;
  isDripCampaign: boolean;
  showMessageBeforeConnectionInput: boolean;
  showMessageAfterConnectionInput: boolean;
  showDripInput: boolean;
  isFormValid: boolean = false;
  errorMessage: string = ' is turned on, enter a message or turn option off.';
  maxLengthError: boolean = false;
  maxLengthErrMessage: string = '275 max characters allowed';
  firstInteraction: any;
  firstDrip: any;
  secondDrip: any;
  finalDrip: any;
  templateForm: FormGroup;
  messageTemplate: any;
  messageTemplatesSub: Subscription;
  campaignsSub: Subscription;
  user: any;
  isProfessional: boolean;

  constructor(
    private store: Store<fromRoot.AppState>
  ) {
    this.templateForm = new FormGroup({
      message_before_connection: new FormControl(this.messageBeforeConnection, [Validators.maxLength(275)]),
      first_interaction: new FormControl(this.firstInteraction),
      first_drip: new FormControl(this.firstDrip),
      second_drip: new FormControl(this.secondDrip),
      final_drip: new FormControl(this.finalDrip)
    });

    this.templateForm.valueChanges.subscribe(value => {
      this.campaignId = useTemplatesStore.getState().campaignId;
      this.messageBeforeConnectLen = this.templateForm?.controls['message_before_connection']?.value.trim().length || 0;
      this.messageBeforeConnectLabel = `Message with connection (${this.messageBeforeConnectLen} characters)`;
    });
    this.user = useUserStore.getState();
    this.isProfessional = this.user?.Product?.allow_professional;

  }

  showTemplateField(templateLen, isEnabled) {
    if (templateLen === 0 && isEnabled) {
      return true;
    } else {
      return false;
    }
  }

  onChange(name, event: any) {
    this.templateForm.controls[name].setValue(event.target.value);
  }

  isError(name) {
    if (!this.templateForm.controls[name].value.trim().length) {
      return true;
    } else {
      return false;
    }
  }

  messageBeforeConnectError() {
    if (this.hasExceededMaxCharacters(this.messageBeforeConnectLen)) {
      this.maxLengthError = true;
      return this.maxLengthErrMessage;
    } else if (this.isError('message_before_connection')) {
      this.maxLengthError = false;
      return `Send message with connection ${this.errorMessage}`;
    }
  }

  hasExceededMaxCharacters(charLength) {
    if (charLength > 275) {
      return true;
    } else {
      return false;
    }
  }

  onSave = () => {
    const updatedTemplate = {
      id: this.messageTemplateId,
      ...this.templateForm.value
    }
    this.store.dispatch(MessageTemplatesActions.updateMessageTemplate({ messageTemplate: updatedTemplate }));
    this.onSaveTemplateClick.emit();
  };

  isDisabled = () => {
    if (this.isVisible) {
      // console.log(`show drip: `, this.showDripInput, this.isDripCampaign);
      // console.log(`first drip len: `, this.templateForm?.controls['first_drip']?.value?.length);
      // console.log(`show message after connection input: `, (this.showMessageBeforeConnectionInput && !this.messageBeforeConnectLen));
      // console.log(`message after connect len: `, this.isMessageAfterConnection, (this.isMessageAfterConnection && !this.messageAfterConnectLen));
      // console.log(`message after disabled: `, (this.maxLengthError && !this.messageAfterConnectLen));
      // console.log(`max error length: `, this.maxLengthError);

      if ((this.isDripCampaign && !this.templateForm?.controls['first_drip']?.value.trim().length) ||
        (this.isMessageBeforeConnection && !this.templateForm.controls['message_before_connection'].value.trim().length) ||
        (this.isMessageAfterConnection && !this.templateForm?.controls['first_interaction']?.value.trim().length) ||
        this.hasExceededMaxCharacters(this.messageBeforeConnectLen)) {
        return true;
      } else {
        return false;
      }
    } else {
      // do nothing to prevent computations on every change detection cycle
    };

  };

  ngOnChanges() {
    this.messageTemplatesSub = this.store.select(messageTemplatesFeatureKey)
      .subscribe(messageTemplatesState => {
        this.messageTemplateId = +this.messageTemplateId;
        let templateId = this.messageTemplateId;
        this.messageTemplate = messageTemplatesState.messageTemplates.find(messageTemplate => messageTemplate.id === templateId);
        this.templateForm.controls["message_before_connection"].setValue(this.messageTemplate?.message_before_connection || '');
        this.templateForm.controls["first_interaction"].setValue(this.messageTemplate?.first_interaction || '');
        this.templateForm.controls["first_drip"].setValue(this.messageTemplate?.first_drip || '');
        this.templateForm.controls["second_drip"].setValue(this.messageTemplate?.second_drip || '');
        this.templateForm.controls["final_drip"].setValue(this.messageTemplate?.final_drip || '');
      });

    this.campaignsSub = this.store.select(campaignsFeatureKey)
      .subscribe(campaignsState => {
        let campaign = campaignsState.campaigns.find(campaign => campaign.id === +this.campaignId);
        this.campaignName = campaign?.campaign_nickname;
        this.messageAfterConnectLen = this.templateForm?.controls['first_interaction']?.value.trim().length || 0;
        this.firstDripLen = this.templateForm?.controls['first_drip']?.value.trim().length || 0;
        this.isMessageBeforeConnection = campaign?.connect_with_message;
        this.isMessageAfterConnection = campaign?.message_after_connection;
        this.isDripCampaign = campaign?.include_drip_campaign;
        this.showMessageBeforeConnectionInput = this.showTemplateField(this.messageBeforeConnectLen, this.isMessageBeforeConnection);
        this.showMessageAfterConnectionInput = this.showTemplateField(this.messageAfterConnectLen, this.isMessageAfterConnection);
        this.showDripInput = this.showTemplateField(this.firstDripLen, this.isDripCampaign);

      });

  }

  ngOnDestroy(): void {
    this.messageTemplatesSub.unsubscribe();
    this.campaignsSub.unsubscribe();
  }

}

