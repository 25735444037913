import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageTemplate } from '../message-template.model';
import { environment } from 'src/environments/environment';
import { RequestHelpersService } from '../../shared/services/request-helpers.service';
import { messageTemplatesFeatureKey } from '../store/message-templates.reducer';
import { useToastStore } from 'src/app/store/toast.store';
import { OnboardingHelperService } from 'src/app/shared/services/onboarding-helper.service';
import { LocalStorageKeys } from '../../constants/storageKeys';

import * as fromRoot from '../../store/app.reducer';
import { useUserStore } from 'src/app/store/user.store';
import { useTemplatesStore } from 'src/app/store/templates.store';

@Injectable({
  providedIn: 'root',
})
export class MessageTemplatesApiService {
  private url: string = `${environment.apiUrl}/message-templates`;
  private createHeaders = () => this.requestHelpers.createHeaders();
  private messageTemplatesSub: Subscription;
  private messageTemplates: MessageTemplate[];
  private error: any;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService,
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private onboardingHelperService: OnboardingHelperService
  ) {}

  getMessageTemplates() {
    return this.http.get<MessageTemplate[]>(this.url, {
      headers: this.createHeaders(),
    });
  }

  addMessageTemplate(messageTemplate: MessageTemplate) {
    useTemplatesStore.setState({ newTemplate: null, newTemplateError: null });
    return this.http.post(this.url, messageTemplate, {
      headers: this.createHeaders(),
    });
  }

  updateMessageTemplate(messageTemplate: MessageTemplate) {
    return this.http.put(`${this.url}/${messageTemplate.id}`, messageTemplate, {
      headers: this.createHeaders(),
    });
  }

  deleteMessageTemplate(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.createHeaders(),
    });
  }

  redirectToNext = (
    messageTemplateName: string,
    addMode: boolean,
    errorMessage?: any
  ) => {
    const isNewUserCreateTemplateFlow =
      this.onboardingHelperService.isNewUserCreateTemplateFlow();
    const isRedirectToCampaign =
      this.onboardingHelperService.isRedirectToCampaign();
    const campaignId = this.onboardingHelperService.getAttachedCampaignId();

    const _doRedirect = (template?: Partial<MessageTemplate>, error?: any) => {
      this.messageTemplatesSub = this.store
        .select(messageTemplatesFeatureKey)
        .subscribe((messageTemplatesState) => {
          this.messageTemplates = messageTemplatesState.messageTemplates;
          this.error = error || messageTemplatesState.error;

          if (this.error) {
            if (this.error.status === 409) {
              this[errorMessage] = 'Message template name already exists.';
              useToastStore.setState({
                isVisible: true,
                content: this[errorMessage],
                variant: 'danger',
              });
            } else {
              useToastStore.setState({
                isVisible: true,
                content: `Opps looks like an error occurred.`,
                variant: 'danger',
              });
            }
          } else {
            if (addMode) {
              useToastStore.setState({
                isVisible: true,
                content: `Message template created successfully`,
              });
              const newTemplate =
                template ||
                this.messageTemplates.find(
                  (m) => m.nickname === messageTemplateName
                );
              const hasDownloadedApp =
                useUserStore.getState().downloaded_macos_app;

              if (isNewUserCreateTemplateFlow && !hasDownloadedApp) {
                this.router.navigate([`/downloads`]);
              } else if (isRedirectToCampaign) {
                localStorage.setItem(
                  LocalStorageKeys.SETTINGS_ACCORDIAN,
                  'active'
                );
                this.router.navigate([`/campaigns//${campaignId}`]);
              } else if (newTemplate) {
                this.router.navigate([`/message-templates//${newTemplate.id}`]);
              }
            } else {
              useToastStore.setState({
                isVisible: true,
                content: `Message template updated successfully`,
              });
              const currentTemplate = this.messageTemplates.find(
                (m) => m.nickname === messageTemplateName
              );
              this.router.navigate([
                `/message-templates//${currentTemplate.id}`,
              ]);
            }
          }
        });
      if (this.messageTemplatesSub) this.messageTemplatesSub.unsubscribe();
      useTemplatesStore.setState({ newTemplate: null, newTemplateError: null });
    };
    const { newTemplate } = useTemplatesStore.getState();

    if (newTemplate === null && addMode) {
      let unsubFromTemplateStore = useTemplatesStore.subscribe(
        (s) => s.newTemplate,
        (t) => {
          const error = useTemplatesStore.getState().newTemplateError;
          if (error) {
            _doRedirect(null, error);
            unsubFromTemplateStore();
            return;
          }

          if (!t) return;

          _doRedirect(t);
          unsubFromTemplateStore();
        }
      );
    } else {
      _doRedirect();
    }
  };
}
