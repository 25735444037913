import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { CampaignsService } from "../services/campaigns.api.service";

import * as CampaignsActions from './campaigns.actions';
import { useCampaignStore } from "src/app/store/campaigns.store";
import { Campaign } from "../campaigns.model";
import { useToastStore } from "src/app/store/toast.store";

const handleSaveError = (error: any) => {
  if (error.status === 403) {
    useToastStore.setState({
      isVisible: true,
      content: 'Must have an active subscription to perform this action.',
      variant: 'danger',
    });
  } else if (error.status === 409) {
    useToastStore.setState({
      isVisible: true,
      content: 'Campaign name already exists.',
      variant: 'danger',
    });
  } else {
    useToastStore.setState({
      isVisible: true,
      content: 'An error occurred. Please try again.',
      variant: 'danger',
    });
  }
}

@Injectable()
export class CampaignEffects {
  getCampaigns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.getCampaigns),
      exhaustMap(() =>
        this.campaignsService.getCampaigns().pipe(
          map(response => {
            return CampaignsActions.getCampaignsSuccess({ payload: response })
          }),
          catchError((error) => of(CampaignsActions.getCampaignsFailure({ error }))))
      )
    )
  );

  updateCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.updateCampaign),
      exhaustMap((action) =>
        this.campaignsService.updateCampaign(action.campaign).pipe(
          map(response => {
            // Dispatch the success action first
            return CampaignsActions.updateCampaignSuccess({ payload: response });
          }),
          // After the success action, chain the getCampaigns action to update the store
          map(() => {
            return CampaignsActions.getCampaigns();
          }),
          catchError((error) => {
            // Handle the error directly here
            handleSaveError(error);
            return of(CampaignsActions.updateCampaignFailure({ error }));
          })
        )
      )
    )
  );

  addCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.addCampaign),
      exhaustMap((action) =>
        this.campaignsService.addCampaign(action.campaign).pipe(
          map((response: Campaign) => {
            CampaignsActions.addCampaignSuccess({ payload: response })
            useCampaignStore.getState().setNewCampaign(response)
            return CampaignsActions.getCampaigns();
          }),
          catchError((error) => {
            useCampaignStore.setState({ newCampaignError: error, newCampaign: {} })
            return of(CampaignsActions.addCampaignFailure({ error }))
          }))
      )
    )
  );

  deleteCampaign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CampaignsActions.deleteCampaign),
      exhaustMap((action) =>
        this.campaignsService.deleteCampaign(action.id).pipe(
          map(response => {
            CampaignsActions.deleteCampaignSuccess({ payload: response })
            return CampaignsActions.getCampaigns();
          }),
          catchError((error) => of(CampaignsActions.deleteCampaignFailure({ error }))))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private campaignsService: CampaignsService,
  ) { }

}
