import { ApiOperation } from './helpers/api-operation';
import { createPipaStore } from './store.helpers';

interface LinkedinConnectionsState {
  linkedinConnections: ApiOperation;
  resolvedReasonsApiOperation: ApiOperation;
  sendMessageApiOperation: ApiOperation;
  campaignId: any;
  connectionId: any;
  connected: any[];
  activeConnection: any;
  connectionActivity: any;
  isActivityLoading: boolean;
  resolvedActivity: any;
  pending: any[];
  allProfiles: any[];
  setCampaignId: (id: any) => void;
  setConnectionId: (id: any) => void;
  setResolvedReasons: (reasons: any) => void;
  reset: () => void;
}

export const useLinkedinConnectionsStore = (() =>
  createPipaStore<LinkedinConnectionsState>((set, get) => ({
    linkedinConnections: new ApiOperation('linkedinConnections', set),
    resolvedReasonsApiOperation: new ApiOperation(
      'resolvedReasonsApiOperation',
      set
    ),
    sendMessageApiOperation: new ApiOperation('sendMessageApiOperation', set),
    campaignId: null,
    connectionId: null,
    activeConnection: [],
    connectionActivity: [],
    isActivityLoading: false,
    resolvedActivity: [],
    connected: [],
    pending: [],
    allProfiles: [],
    setCampaignId: (id) => set({ campaignId: id }),
    setConnectionId: (id) => set({ connectionId: id }),
    setResolvedReasons: (reasons) => {
      set({ resolvedActivity: reasons });
    },
    reset: () =>
      set({
        connected: [],
        pending: [],
        allProfiles: [],
        campaignId: null,
        connectionId: null,
        activeConnection: [],
        connectionActivity: [],
        resolvedActivity: [],
        isActivityLoading: false,
      }),
  })))();
