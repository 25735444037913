<div class="choice-button-group flex__row--wrap">
  <div *ngFor="let option of options" class="option-item">
    <button
      class="choice-button"
      [disabled]="disabled"
      [class.selected]="isOptionSelected(option)"
      [ngClass]="{
        'size-small': size === 'small',
        'size-medium': size === 'medium',
        'size-large': size === 'large'
      }"
      (click)="toggleOption(option)"
    >
      {{ option }}
    </button>
  </div>
</div>
