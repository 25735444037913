<div class="profile-cell-container flex _easeTopToBottom" *ngIf="!!link">
  <div class="profile-cell-content-wrapper">
    <div class="profile-details-wrapper">
      <profile-image
        style="position: absolute; left: -5px; padding: 5px"
        [image]="image"
        [fullName]="name"
      ></profile-image>
      <div class="details">
        <div class="flex__center--start">
          <span class="name">{{ name }}</span>
          <app-badge
            [size]="'small'"
            [withIcon]="false"
            *ngIf="sentiment"
            [text]="sentiment"
            [variant]="sentiment === 'Interested' ? 'filled' : 'danger'"
          ></app-badge>
        </div>
        <p class="description">{{ description }}</p>
      </div>
    </div>
    <div class="profile-detail-item-wrapper flex__row--space-evenly">
      <detail-item
        class="connection-status"
        [label]="'Status'"
        [value]="status"
      ></detail-item>
      <detail-item
        class="outreach-activity-status"
        [label]="'Outreach Activity Status'"
        [value]="outReachStatus"
      ></detail-item>
      <detail-item
        *ngIf="connectedOn"
        class="connected-on"
        [label]="'Connected'"
        [value]="connectedOn"
      ></detail-item>
      <detail-item
        *ngIf="!connectedOn"
        class="last-activity-date"
        [label]="'Last Activity'"
        [value]="lastActivityDate"
      ></detail-item>
      <detail-item
        class="date-added"
        [label]="'Date added'"
        [value]="dateAdded"
      ></detail-item>
    </div>
    <!-- <a
      rel="noreferrer"
      target="_blank"
      href="{{ link }}"
      class="cta-link"
      title="View Linkedin Profile"
    >
      <img
        src="{{ arrowRightIcon }}"
        alt="arrow-right"
        draggable="false"
        style="width: 24px"
      />
    </a> -->
    <menu
      class="profile-cell-menu"
      [labels]="menuLabels"
      (menuClick)="onMenuClick($event)"
    >
      <img
        class="menu-icon"
        src="assets/icons/overflow-vertical.svg"
        draggable="false"
        alt="menu icon"
      />
    </menu>
  </div>
</div>
