import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { messageTemplatesFeatureKey } from '../store/message-templates/message-templates.reducer';
import * as MessageTemplatesActions from '../store/message-templates/message-templates.actions';
import * as fromRoot from '../store/app.reducer';

@Injectable({
  providedIn: 'root',
})

export class MessageTemplatesResolver {
  constructor(private store: Store<fromRoot.AppState>) { }

  resolve(): Observable<any> {
    this.store.dispatch(MessageTemplatesActions.getMessageTemplates());

    return this.store.select(messageTemplatesFeatureKey).pipe(
      filter((messageTemplatesState) => messageTemplatesState.isLoading === false), // Wait until loading is complete
      take(1), // Complete the observable once the state is ready
      switchMap((messageTemplatesState) => {
        if (!messageTemplatesState.messageTemplates || messageTemplatesState.messageTemplates.length === 0) {
          return of([]); // Return an empty array if no messageTemplates exist
        }
        return of(messageTemplatesState.messageTemplates); // Return the messageTemplates if they exist
      }),
      catchError((error) => {
        console.error('Error resolving messageTemplates:', error);
        return of([]); // Handle errors gracefully by returning an empty array
      })
    );
  }
}
