import { Component, Input, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input() image: string;
  @Input() id: any;
  @Input() name: string;
  @Input() description: string;
  @Input() sentiment: string;
  @Input() link: string;
  arrowRightIcon = 'assets/icons/arrow-top-right.svg';

  constructor(
    private renderer: Renderer2,
  ) { }

  setImage(url) {
    if (window.location.origin.includes('localhost')) {
      this.image = '';
      return;
    }
    let img = this.renderer.createElement('IMG');
    img.src = url;
    img.onerror = () => {
      this.image = '';
    };
  }

  ngOnInit(): void {
    this.setImage(this.image);
    if (!this.name) this.name = 'N/A';
    if (!this.description) this.description = '---';
  }
}
