import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from '../directives/click-outside.directive';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'menu',
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  @Input() labels: string[] = [];
  @Output() menuClick: EventEmitter<string> = new EventEmitter<string>();
  dropdownOpen: boolean = false;

  toggleMenu() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  handleClick(label: string) {
    this.menuClick.emit(label); // Emit the clicked label
  }
}
