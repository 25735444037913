import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Campaign } from '../campaigns.model';
import { campaignsFeatureKey } from '../store/campaigns.reducer';
import { RequestHelpersService } from '../../shared/services/request-helpers.service';
import { OnboardingHelperService } from '../../shared/services/onboarding-helper.service';

import { useCampaignStore } from 'src/app/store/campaigns.store';
import { useToastStore } from 'src/app/store/toast.store';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromRoot from '../../store/app.reducer';

@Injectable({
  providedIn: 'any',
})
export class CampaignsService {
  private url: string = `${environment.apiUrl}/marketing-campaigns`;
  private createHeaders = () => this.requestHelpers.createHeaders();
  private campaignsSub: Subscription;
  private campaigns: Campaign[];
  private error: any;

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService,
    private store: Store<fromRoot.AppState>,
    private router: Router,
    private onboardingHelperService: OnboardingHelperService
  ) { }

  getCampaigns() {
    return this.http.get<Campaign[]>(this.url, {
      headers: this.createHeaders(),
    });
  }

  getCampaignCsv(campaignId: number): Observable<Blob> {
    return this.http.get(`${this.url}/${campaignId}/profiles/csv`, {
      responseType: 'blob',
      headers: this.createHeaders(),
    });
  }

  getDailyLimitInfo(campaignId: number) {
    return this.http.get(`${this.url}/limit/${campaignId}`, {
      headers: this.createHeaders(),
    });
  }

  addCampaign(campaign: Campaign) {
    useCampaignStore.setState({ newCampaign: null, newCampaignError: null });
    return this.http.post(this.url, campaign, {
      headers: this.createHeaders(),
    });
  }

  updateCampaign(campaign: Campaign) {
    return this.http.put(`${this.url}/${campaign.id}`, campaign, {
      headers: this.createHeaders(),
    });
  }

  updateDailyLimitPercentage(
    dailyLimits: { campaignId: number; limitPerc: number }[]
  ) {
    return this.http.put(`${this.url}/limit`, dailyLimits, {
      headers: this.createHeaders(),
    });
  }

  rebalanceDailyLimits() {
    return this.http.put(`${this.url}/limit/rebalance`, {
      headers: this.createHeaders(),
    });
  }

  deleteCampaign(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.createHeaders(),
    });
  }

  redirectToNext = (
    campaignName: string,
    addMode: boolean,
    errorMessage?: any,
    campaignId?: number
  ) => {
    const isNewUserCampaignFlow =
      this.onboardingHelperService.isNewUserCampaignFlow();

    const _doRedirect = (campaign?: Partial<Campaign>, error?: any) => {
      this.campaignsSub = this.store
        .select(campaignsFeatureKey)
        .subscribe((campaignsState) => {
          this.campaigns = campaignsState.campaigns;
          this.error = error || campaignsState.error;

          if (this.error) {
            console.error('Error creating campaign:', this.error);
            if (this.error.status === 403) {
              this[errorMessage] = 'Must have an active subscription to create campaigns.';
              useToastStore.setState({
                isVisible: true,
                content: this[errorMessage],
                variant: 'danger',
              });
              this.router.navigate([`/billing-plans`]);
            }
            else if (this.error.status === 409) {
              this[errorMessage] = 'Campaign name already exists.';
              useToastStore.setState({
                isVisible: true,
                content: this[errorMessage],
                variant: 'danger',
              });
            } else {
              useToastStore.setState({
                isVisible: true,
                content: `Opps looks like an error occurred.`,
                variant: 'danger',
              });
            }
          } else {
            if (addMode) {
              useToastStore.setState({
                isVisible: true,
                content: `Campaign created successfully`,
              });
              const newCampaign =
                campaign ||
                this.campaigns.find(
                  (campaign) => campaign.campaign_nickname === campaignName
                );
              if (newCampaign && isNewUserCampaignFlow)
                this.router.navigate(
                  [`/message-templates/add-message-template`],
                  { queryParams: { cid: newCampaign.id } }
                );
              else if (newCampaign) {
                this.router.navigate([`/campaigns//${newCampaign.id}`]);
              }
            } else {
              useToastStore.setState({
                isVisible: true,
                content: `Campaign updated successfully`,
              });
              this.router.navigate([`/campaigns//${campaignId}`]);
            }
          }
        });
      if (this.campaignsSub) this.campaignsSub.unsubscribe();
      useCampaignStore.setState({ newCampaign: null, newCampaignError: null });
    };

    const { newCampaign } = useCampaignStore.getState();

    if (newCampaign === null && addMode) {
      let unsubFromCampaignStore = useCampaignStore.subscribe(
        (s) => s.newCampaign,
        (c) => {
          const error = useCampaignStore.getState().newCampaignError;
          if (error) {
            _doRedirect(null, error);
            unsubFromCampaignStore();
            return;
          }

          if (!c) return;

          _doRedirect(c);
          unsubFromCampaignStore();
        }
      );
    } else _doRedirect();
  };
}
