import {
  Directive,
  Input,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { SkeletonLoadingComponent } from '../skeleton-loading/skeleton-loading.component';

const random = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

@Directive({ selector: '[skeleton]' })
export class SkeletonDirective {
  @Input('skeleton') isLoading = false;
  @Input('skeletonRepeat') size = 1;
  @Input('skeletonWidth') width: string;
  @Input('skeletonHeight') height: string;
  @Input('skeletonBorderRadius') borderRadius: string;
  @Input('skeletonClassName') className: string;

  constructor(private tpl: TemplateRef<any>, private vcr: ViewContainerRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.isLoading) {
      this.vcr.clear();

      if (changes.isLoading.currentValue) {
        Array.from({ length: this.size }).forEach(() => {
          const ref = this.vcr.createComponent(SkeletonLoadingComponent);

          Object.assign(ref.instance, {
            width: this.width === 'rand' ? `${random(120, 320)}px` : this.width,
            height: this.height,
            borderRadius: this.borderRadius,
            className: this.className,
          });
        });
      } else {
        this.vcr.createEmbeddedView(this.tpl);
      }
    }
  }
}
