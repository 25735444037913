import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { map, Observable, take } from "rxjs";
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/app.reducer';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {

  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    return this.store.select('auth').pipe(
      take(1),
      map(authState => {
        return authState.user;
      }),
      map(user => {
        const isAuthenticated = !!user;
        if (isAuthenticated) {
          return true
        }
        return this.router.createUrlTree(['/auth'])
      }));
  }

}
