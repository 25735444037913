import { Component, Input } from '@angular/core';

@Component({
  selector: 'modal-side-bar',
  // standalone: true,
  // imports: [],
  templateUrl: './modal-side-bar.component.html',
  styleUrl: './modal-side-bar.component.scss',
})
export class ModalSideBarComponent {
  @Input() header?: string;

  constructor() {}
}
