<app-modal [visible]="visible" [usePadding]="false" [isDarkTheme]="true">
  <div class="waitlist-wrapper">
    <div class="flex__row--space-between waitlist-header-wrapper">
      <span class="waitlist-header s3">Join the Windows Waitlist</span>
      <img
        (click)="onClose()"
        src="assets/icons/x-close.svg"
        alt="X"
        draggable="false"
        class="_close-icon close-btn"
      />
    </div>
    <form
      class="form-group windows-waitlist-form"
      #windowsWaitlistForm="ngForm"
      (ngSubmit)="onSubmit(windowsWaitlistForm)"
    >
      <div class="form-group waitlist">
        <label for="email">Email</label>
        <input
          required
          type="text"
          name="email"
          class="form-control waitlist-input"
          id="email"
          [(ngModel)]="windowsWaitlistForm.email"
          autocomplete="email"
          #email="ngModel"
        />
      </div>
      <div class="form-group waitlist">
        <label for="name">Full Name</label>
        <input
          required
          type="text"
          name="name"
          class="form-control waitlist-input"
          id="name"
          [(ngModel)]="windowsWaitlistForm.name"
          autocomplete="name"
          #name="ngModel"
        />
      </div>
      <div>
        <button
          id="join_btn"
          [attr.class]="
            success ? 'flex__center waitlist-success' : 'flex__center'
          "
          [disabled]="!windowsWaitlistForm.valid || loading"
        >
          {{ success ? "You're on the list!" : "Join" }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
