<div class="backdrop">
  <div class="alert-box">
    <div class="dialog-wrapper">
      <p class="flex__center message">{{message}}</p>
    </div>
    <div class="flex__row--space-around alert-box-actions">
      <app-button (click)="onClose()" class="{{cancelTheme}}" label="{{cancelLabel}}"></app-button>
      <app-button label="{{label}}" (click)="onAction()" *ngIf="hasAction" class="{{theme}}">
      </app-button>
    </div>
  </div>
</div>