import { Component, Input } from '@angular/core';
import { FormatPhoneNumber } from 'src/app/helpers/number-formatting';

@Component({
  selector: 'profile-vertical-card',
  templateUrl: './profile-vertical-card.component.html',
  styleUrl: './profile-vertical-card.component.scss',
})
export class ProfileVerticalCardComponent {
  @Input() image?: string;
  @Input() fullName?: string;
  @Input() company?: string;
  @Input() jobTitle?: string;
  @Input() connectedOn?: Date;
  @Input() location?: string;
  @Input() email?: string;
  @Input() phone?: string;

  constructor() {}

  formatPhoneNumber(phone: string) {
    return FormatPhoneNumber(phone);
  }
}
