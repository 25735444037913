import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { UserState } from './user.interface';
import * as fromRoot from '../app.reducer';

export const initialState: UserState = {
  id: '',
  email: '',
  phone_number: '',
  full_name: '',
  industry: '',
  calendly_url: '',
  linkedin_authenticated: false,
  product_id: null,
  subscription_product: '',
  subscription_billing_date: null,
  Product: null,
  subscription_active: false,
  subscription_status: '',
  subscription_canceled: false,
  payment_valid: false,
  acknowledged_tour: false,
  phone_number_verified: false,
  cookie_expiry: '',
  linkedin_lead_list: null,
  downloaded_macos_app: false,
  isSubscribed: false,
  hasLeadLists: false,
  hasCampaigns: false,
  hasMessages: false,
  isLoading: false, // Ensure this property is set
};

// export const userReducer = createReducer(
//   initialState,

//   // Handle fetching user details
//   on(UserActions.fetchUserDetailsSuccess, (state, { user }) => ({
//     ...state,
//     ...user,
//     isAuthenticated: true,
//     isSubscribed: !!user.subscription_active
//   })),

//   // Handle failed fetch of user details
//   on(UserActions.fetchUserDetailsFail, (state, { error }) => ({
//     ...state,
//     isAuthenticated: false,
//     authError: error,
//   }))
// );

export const userReducer = createReducer(
  initialState,

  // Set loading state when fetching user details
  on(UserActions.fetchUserDetailsStart, (state) => ({
    ...state,
    isLoading: true,
  })),

  // Handle successful user details fetch
  on(UserActions.fetchUserDetailsSuccess, (state, { user }) => ({
    ...state,
    ...user,
    isLoading: false,
    isSubscribed: !!user.subscription_active,
  })),

  // Handle failed user details fetch
  on(UserActions.fetchUserDetailsFail, (state, { error }) => ({
    ...state,
    isLoading: false,
    authError: error,
  })),

  // TODO: Reset user state on logout
  // on(UserActions.logout, () => initialState)
);

export const userProfileFeatureKey = "user";

export const messageTemplatesFeatureState = createFeatureSelector<fromRoot.AppState>(userProfileFeatureKey);
