import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-header',
  template: `
  <div class="card-header-wrapper">
    <h5 class="card-header {{variant}}">{{title}}</h5>
    <ng-content>
    </ng-content>
  </div>
  `,
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() variant?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
