import { ApiOperation } from './helpers/api-operation';
import { createPipaStore } from './store.helpers';


export const PhoneVerifyErrorCodes = {
    invalidVerifyCode: "VERIFY_PIN_INVALID"
}

interface PhoneVerifyState {
    sendPhoneVerify: ApiOperation
    submitPhoneVerifyPin: ApiOperation
}

export const usePhoneVerifyStore = (() => createPipaStore<PhoneVerifyState>((set, get) => ({
    sendPhoneVerify: new ApiOperation('sendPhoneVerify', set),
    submitPhoneVerifyPin: new ApiOperation('submitPhoneVerifyPin', set)
})))()