import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'any',
})
export class VariableHighlightService {
  constructor(private sanitizer: DomSanitizer) {}

  highlightVariable = (textBlock: any) => {
    if (textBlock.includes('$prospect')) {
      const formattedTextBlock = textBlock.replace(
        /\$prospect/g,
        `<strong style="color: #635AFF;">$prospect</strong>`
      );
      return this.sanitizer.bypassSecurityTrustHtml(formattedTextBlock);
    } else {
      return textBlock;
    }
  };
}
