import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LeadListContract } from 'src/app/store/lead-list/lead-list.interface';

@Injectable({
  providedIn: 'any'
})
export class LeadListService {
  private url: string = `${environment.apiUrl}/lead-lists`;
  private createHeaders = () => this.requestHelpers.createHeaders();

  constructor(private requestHelpers: RequestHelpersService, private http: HttpClient) { }

  getLeadListById(id: number, page?: number, pageSize?: number) {
    let params = new HttpParams();
    if (page !== undefined) {
      params = params.set('page', page.toString());
    }
    if (pageSize !== undefined) {
      params = params.set('pageSize', pageSize.toString());
    }
    return this.http.get(`${this.url}/${id}`, {
      headers: this.createHeaders(),
      params: params
    });
  }

  getLeadLists() {
    return this.http.get(this.url, {
      headers: this.createHeaders(),
    });
  }

  createLeadList(leadList: any) {
    return this.http.post(this.url, leadList, {
      headers: this.createHeaders(),
    });
  }

  updateLeadList(id: number, leadList: any) {
    return this.http.put(`${this.url}/${id}`, leadList, {
      headers: this.createHeaders(),
    });
  }

  deleteLeadList(id: number) {
    return this.http.delete(`${this.url}/${id}`, {
      headers: this.createHeaders(),
    });
  }

  addLeadsToList(id: number, leads: any) {
    return this.http.post(`${this.url}/${id}/add-linkedin-leads`, leads, {
      headers: this.createHeaders(),
    });
  }

  transferLeads(targetLeadListId: number, leadIds: number[]) {
    const body = {
      targetLeadListId,
      leadIds
    };
    return this.http.post(`${this.url}/transfer-leads`, body, {
      headers: this.createHeaders(),
    });
  }
}
