import { User } from "../user.model";
import * as AuthActions from './auth.actions';
import { createReducer, on } from '@ngrx/store';

export interface State {
  user: User | null;
  authError: string | null;
  loading: boolean;
}

export const initialState: State = {
  user: null,
  authError: null,
  loading: false
};

export const authReducer = createReducer(
  initialState,

  on(AuthActions.authenticateSuccess, (state, action) => {
    const user = new User(action.token);
    return {
      ...state,
      authError: null,
      loading: false,
      user
    };
  }),


  on(AuthActions.logOut, state => {
    return {
      ...state,
      user: null
    };
  }),

  on(AuthActions.loginStart, AuthActions.signUpStart, state => {
    return {
      ...state,
      loading: true,
      authError: null
    };
  }),

  on(AuthActions.authenticateFail, (state, action) => {
    return {
      ...state,
      user: null,
      loading: false,
      authError: action.error
    };
  }),

  on(AuthActions.clearError, state => {
    return {
      ...state,
      authError: null
    };
  })
);
