<div
  *ngIf="!isMobile"
  role="banner"
  class="flex__center nav-wrapper"
  [ngStyle]="{
    background: transparent
      ? 'transparent'
      : 'linear-gradient(to left top, #0D4557, #001241)'
  }"
>
  <div class="flex__row--space-between nav_content">
    <div *ngIf="activeRoute !== 'linkedin-login'" class="logo">
      <img
        class="pipa"
        src="assets/images/pipa-white.png"
        draggable="false"
        alt="pipa"
      />
    </div>
    <div *ngIf="!transparent && hasRawConnections" class="nav-wrapper__search">
      <app-debouce-search-input
        [theme]="'dark'"
        [placeholder]="'Search connections...'"
        [searchMethod]="searchConnections"
        style="width: 300px; padding: 0"
        [displayConfig]="{
          imageProperty: 'image_url',
          primaryText: 'connection_name',
          secondaryText: 'job_title',
          secondLineText: 'location'
        }"
        (selection)="handleSelection($event)"
      ></app-debouce-search-input>
    </div>
    <div class="flex__center">
      <app-verify-phone *ngIf="!transparent"></app-verify-phone>
      <app-account-menu
        [user]="user"
        [userName]="userName"
        *ngIf="!transparent"
      ></app-account-menu>
    </div>
  </div>
</div>
