import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() options: any[] = [];
  @Input() disabled: boolean;
  @Input() name?: string = '';
  @Input() id?: string;
  @Input() label?: string;
  @Input() value = '';
  @Input() placeholder?: string = 'Select an option';
  @Input() defaultValue?: string;
  @Input() selected?: any = null;
  @Input() isDarkTheme?: boolean = false;
  @Input() theme?: 'dark' | 'light' = 'light';
  @Input() isIntlCodeInput?: boolean = false;
  @Input() allowRemoveSelected?: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.selected = this?.options?.find((o) => o.value == this.defaultValue);
  }
}
