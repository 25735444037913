import { Component, Input } from '@angular/core';

@Component({
  selector: 'detail-item',
  template: `
    <div class="detail-item-wrapper flex__column--center">
      <span class="item-value">{{ value }}</span>
      <span class="item-label">{{ label }}</span>
    </div>
  `,
  styleUrls: ['./detail-item.component.scss'],
})
export class DetailItemComponent {
  @Input() value: string;
  @Input() label: string;
}
