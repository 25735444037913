import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MessageTemplatesApiService } from "../services/message-templates-api.service";

import * as MessageTemplatesActions from './message-templates.actions';
import { useTemplatesStore } from "src/app/store/templates.store";
import { MessageTemplate } from "../message-template.model";

@Injectable()
export class MessageTemplatesEffects {
  getMessageTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageTemplatesActions.getMessageTemplates),
      exhaustMap(() =>
        this.messageTemplatesService.getMessageTemplates().pipe(
          map(response => {
            // console.log("getMessageTemplates$ response:::", response)
            return MessageTemplatesActions.getMessageTemplatesSuccess({ payload: response })
          }),
          catchError((error) => of(MessageTemplatesActions.getMessageTemplatesFailure({ error }))))
      )
    )
  );

  updateMessageTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageTemplatesActions.updateMessageTemplate),
      exhaustMap((action) =>
        this.messageTemplatesService.updateMessageTemplate(action.messageTemplate).pipe(
          map(response => {
            // console.log("updateMessageTemplate$ response:::", response)
            MessageTemplatesActions.updateMessageTemplateSuccess({ payload: response })
            return MessageTemplatesActions.getMessageTemplates();
          }),
          catchError((error) => of(MessageTemplatesActions.updateMessageTemplateFailure({ error }))))
      )
    )
  );

  addMessageTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageTemplatesActions.addMessageTemplate),
      exhaustMap((action) =>
        this.messageTemplatesService.addMessageTemplate(action.messageTemplate).pipe(
          map((response: MessageTemplate) => {
            MessageTemplatesActions.addMessageTemplateSuccess({ payload: response })
            useTemplatesStore.getState().setNewTemplate(response)
            return MessageTemplatesActions.getMessageTemplates();
          }),
          catchError((error) => {
            useTemplatesStore.setState({ newTemplateError: error, newTemplate: {} })
            return of(MessageTemplatesActions.addMessageTemplateFailure({ error }))
          }))
      )
    )
  );

  deleteMessageTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MessageTemplatesActions.deleteMessageTemplate),
      exhaustMap((action) =>
        this.messageTemplatesService.deleteMessageTemplate(action.id).pipe(
          map(response => {
            // console.log("deleteMessageTemplate$ response:::", response)
            MessageTemplatesActions.deleteMessageTemplateSuccess({ payload: response })
            return MessageTemplatesActions.getMessageTemplates();
          }),
          catchError((error) => of(MessageTemplatesActions.deleteMessageTemplateFailure({ error }))))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private messageTemplatesService: MessageTemplatesApiService,
  ) { }
}
