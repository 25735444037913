import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() message: string;
  @Input() theme: string;
  @Input() hasAction: boolean;
  @Input() label: string;
  @Input() cancelLabel: string = 'Dismiss';
  @Input() cancelTheme: string = 'dismiss';
  @Output() close = new EventEmitter<void>();
  @Output() onClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.close.emit();
  }

  onAction() {
    this.onClick.emit();
  }
}
