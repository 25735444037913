import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as LeadListActions from './lead-list.actions';
import { LeadListsContract } from './lead-list.interface';

export const initialState: LeadListsContract = {
  leadLists: [],
};

export const leadListReducer = createReducer(
  initialState,

  // Handle successful fetch of lead lists
  on(LeadListActions.fetchLeadListsSuccess, (state, { leadLists }) => ({
    ...state,
    leadLists,
  })),

  // Handle adding a new lead list
  on(LeadListActions.addLeadListSuccess, (state, { leadList }) => ({
    ...state,
    leadLists: [...state.leadLists, leadList],
  }))
);

export const leadListFeatureKey = 'leadLists';

export const leadListFeatureState = createFeatureSelector<LeadListsContract>(leadListFeatureKey);
