import { Injectable } from '@angular/core';

declare const gtag: Function;

const orUndefined = (value) => value || 'undefined';

type EventKeys = keyof typeof Events;
type EventType = (typeof Events)[EventKeys];

type EventCategoryKeys = keyof typeof EventCategories;
type EventCategoryType = (typeof EventCategories)[EventCategoryKeys];

type EventMetaDataType = {
  event_label: string;
  value: string;
  page_path?: string;
  event_category?: string;
};

@Injectable({
  providedIn: 'any',
})
export class AnalyticsManagerService {
  constructor() { }

  static recordEvent = (
    eventName: EventType,
    eventCategory: EventCategoryType = EventCategories.general,
    overrides: Partial<EventMetaDataType> = {}
  ) => {
    const eventMeta = EventMetaData[eventName];
    const eventDataObject = {
      event_category: eventCategory,
      event_label: orUndefined(eventMeta?.event_label),
      value: orUndefined(eventMeta?.value),
      ...overrides,
    };
    gtag('event', eventName, eventDataObject);
  };

  static recordButtonClick = (eventName: EventType) => {
    AnalyticsManagerService.recordEvent(eventName, EventCategories.click);
  };

  static recordError = (eventName: EventType, errorResponse: any) => {
    AnalyticsManagerService.recordEvent(eventName, EventCategories.error, {
      event_label: 'Error',
      value: errorResponse,
    });
  };

  static recordPageView = (path: string) => {
    AnalyticsManagerService.recordEvent(
      Events.pageView,
      EventCategories.pageView,
      { event_label: `Page Viewed`, value: path, page_path: path }
    );
  };
}

export const EventCategories = {
  general: 'GENERAL',
  pageView: 'PAGE_VIEW',
  click: 'BUTTON_CLICK',
  error: 'ERROR',
} as const;

export const Events = {
  runCampaignClicked: 'RUN_CAMPAIGN_BUTTON_CLICKED',
  authenticateOnDesktopClicked: 'AUTHENTICATE_ON_DESKTOP_BUTTON_CLICKED',
  runLeadListClicked: 'RUN_LEAD_LIST_BUTTON_CLICKED',
  runSalesNavigatorClicked: 'RUN_SALES_NAVIGATOR_BUTTON_CLICKED',
  downloadCSVClicked: 'DOWNLOAD_CSV_BUTTON_CLICKED',
  downloadAppClicked: 'DOWNLOAD_APP_BUTTON_CLICKED',
  downloadAppleChipClicked: 'DOWNLOAD_APPLE_CHIP_BUTTON_CLICKED',
  downloadIntelChipClicked: 'DOWNLOAD_INTEL_CHIP_BUTTON_CLICKED',
  activityLogClicked: 'ACTIVITY_LOG_CLICKED',
  addCampaignClicked: 'ADD_CAMPAIGN_CLICKED',
  addLeadListClicked: 'ADD_LEAD_LIST_CLICKED',
  addMessageTemplateClicked: 'ADD_MESSAGE_TEMPLATE_CLICKED',
  viewLinkedinProfileClicked: 'VIEW_LINKEDIN_PROFILE_CLICKED',
  pipaSuggestMessageClicked: 'PIPA_SUGGEST_MESSAGE_CLICKED',
  toggleMarkConnectionAsComplete: 'TOGGLE_MARK_CONNECTION_AS_COMPLETE',
  markConnectionAsCompleteClicked: 'MARK_CONNECTION_AS_COMPLETE_CLICKED',
  toggleMarkConnectionAsActive: 'TOGGLE_MARK_CONNECTION_AS_ACTIVE',
  markConnectionAsActiveClicked: 'MARK_CONNECTION_AS_ACTIVE_CLICKED',
  toggleSendMessage: 'TOGGLE_SEND_MESSAGE',
  sendMessageClicked: 'SEND_MESSAGE_CLICKED',
  sendMessageError: 'SEND_MESSAGE_ERROR',
  addLeadListCampaignClicked: 'ADD_LEAD_LIST_CAMPAIGN_CLICKED',
  addCustomFiltersCampaignClicked: 'ADD_CUSTOM_FILTERS_CAMPAIGN_CLICKED',
  pageView: 'page_view',
} as const;

const EventMetaData: { [key: string]: EventMetaDataType } = {
  [Events.runCampaignClicked]: {
    event_label: 'Run Campaign Click',
    value: 'User ran the campaign from the web app',
  },
  [Events.authenticateOnDesktopClicked]: {
    event_label: 'Authenticate On Desktop Click',
    value: 'User authenticated on desktop',
  },
  [Events.runLeadListClicked]: {
    event_label: 'Run Lead List Click',
    value: 'User ran the lead list from the web app',
  },
  [Events.runSalesNavigatorClicked]: {
    event_label: 'Run Sales Navigator Click',
    value: 'User ran the sales navigator from the web app',
  },
  [Events.downloadCSVClicked]: {
    event_label: 'Download CSV Click',
    value: 'User downloaded the CSV from the web app',
  },
  [Events.downloadAppClicked]: {
    event_label: 'Download App Click',
    value: 'User downloaded the app from the web app',
  },
  [Events.downloadAppleChipClicked]: {
    event_label: 'Download Apple Chip Click',
    value: 'User downloaded the apple chip from the web app',
  },
  [Events.downloadIntelChipClicked]: {
    event_label: 'Download Intel Chip Click',
    value: 'User downloaded the intel chip from the web app',
  },
  [Events.activityLogClicked]: {
    event_label: 'Activity Log Click',
    value: 'User viewed the activity log',
  },
  [Events.addCampaignClicked]: {
    event_label: 'Add Campaign Click',
    value: 'User added a campaign from the web app',
  },
  [Events.addLeadListClicked]: {
    event_label: 'Add Lead List Click',
    value: 'User added a lead list from the web app',
  },
  [Events.addMessageTemplateClicked]: {
    event_label: 'Add Message Template Click',
    value: 'User added a message template from the web app',
  },
  [Events.viewLinkedinProfileClicked]: {
    event_label: 'View Linkedin Profile Click',
    value: 'User viewed a linkedin profile from the web app',
  },
  [Events.pipaSuggestMessageClicked]: {
    event_label: 'Pipa Suggest Message Click',
    value: 'User clicked the suggest message button in the pipa modal',
  },
  [Events.toggleMarkConnectionAsComplete]: {
    event_label: 'Toggle Mark Connection As Complete',
    value: 'User toggled the mark connection as complete button',
  },
  [Events.markConnectionAsCompleteClicked]: {
    event_label: 'Mark Connection As Complete Click',
    value: 'User marked a connection as complete',
  },
  [Events.toggleMarkConnectionAsActive]: {
    event_label: 'Toggle Mark Connection As Active',
    value: 'User toggled the mark connection as active button',
  },
  [Events.markConnectionAsActiveClicked]: {
    event_label: 'Mark Connection As Active Click',
    value: 'User marked a connection as active',
  },
  [Events.toggleSendMessage]: {
    event_label: 'Toggle Send Message',
    value: 'User toggled the send message button',
  },
  [Events.sendMessageClicked]: {
    event_label: 'Send Message Click',
    value: 'User sent a message',
  },
  [Events.sendMessageError]: {
    event_label: 'Send Message Error',
    value: 'User encountered an error while sending a message',
  },
  [Events.addLeadListCampaignClicked]: {
    event_label: 'Add Lead List Campaign Click',
    value: 'User added a lead list campaign from the web app',
  },
  [Events.addCustomFiltersCampaignClicked]: {
    event_label: 'Add Custom Filters Campaign Click',
    value: 'User added a custom filters campaign from the web app',
  },
};
