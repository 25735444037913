import { createAction, props } from '@ngrx/store';

export const LOGIN_START = '[Auth] Login Start';
export const AUTHENTICATE_SUCCESS = '[Auth] Login';
export const AUTHENTICATE_FAIL = '[Auth] Login Fail';
export const LOGOUT = '[Auth] Logout';
export const SIGN_UP_START = '[Auth] Sign Up Start';
export const CLEAR_ERROR = '[Auth] Clear Error';
export const AUTO_LOGIN = '[Auth] Auto Login';

export const loginStart = createAction(
  LOGIN_START,
  props<{ email: string; password: string }>()
);

export const authenticateSuccess = createAction(
  AUTHENTICATE_SUCCESS,
  props<{
    token: string;
    redirect: boolean;
  }>()
);

export const authenticateFail = createAction(AUTHENTICATE_FAIL, props<any>());

export const logOut = createAction(LOGOUT);

export const signUpStart = createAction(
  SIGN_UP_START,
  props<{
    name: string;
    email: string;
    password: string;
    phone_number: string;
    accepted_terms: boolean;
    phone_country_code: string;
  }>()
);

export const clearError = createAction(CLEAR_ERROR);

export const autoLogin = createAction(AUTO_LOGIN);
