import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card',
  template: `
  <div class="app__card_container" [ngStyle]="{
        'box-shadow': (useShadow) ? '0 1px 10px 0 rgba(0, 28, 36, 0.1)' : 'none'}">
    <ng-content></ng-content>
  </div>
`,
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input() useShadow: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
