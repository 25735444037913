import { createAction, props } from '@ngrx/store';
import { LeadListContract } from './lead-list.interface';

// Action to fetch lead lists
export const fetchLeadListsStart = createAction(
  '[LeadList] Fetch LeadLists Start'
);

export const fetchLeadListsSuccess = createAction(
  '[LeadList] Fetch LeadLists Success',
  props<{ leadLists: LeadListContract[] }>()
);

export const fetchLeadListsFail = createAction(
  '[LeadList] Fetch LeadLists Fail',
  props<{ error: string }>()
);

// Action to add a lead list
export const addLeadListStart = createAction(
  '[LeadList] Add LeadList Start',
  props<{ leadList: LeadListContract }>()
);

export const addLeadListSuccess = createAction(
  '[LeadList] Add LeadList Success',
  props<{ leadList: LeadListContract }>()
);

export const addLeadListFail = createAction(
  '[LeadList] Add LeadList Fail',
  props<{ error: string }>()
);

export const resetLeadLists = createAction(
  '[LeadList] Reset LeadLists'
);
