import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import * as fromRoot from '../../../store/app.reducer';
import * as AuthActions from '../../../auth/store/auth.actions';
import * as LeadListActions from '../../../store/lead-list/lead-list.actions';
import * as MessageTemplateActions from '../../../store/message-templates/message-templates.actions';
import * as CampaignsActions from '../../../store/campaigns/campaigns.actions';
import { UserState } from 'src/app/store/user/user.interface';
import { SharedModule } from 'src/app/shared/shared.module';


@Component({
  selector: 'app-account-menu',
  templateUrl: './account-menu.component.html',
  standalone: true,
  imports: [ClickOutsideDirective, SharedModule],
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  dropdownOpen: boolean = false;
  @Input() onNavClick: any;
  @Input() user: UserState;
  privacyUrl = 'https://pipa.cloud/privacy';
  termsUrl = 'https://pipa.cloud/terms';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.AppState>,
  ) { }

  ngOnInit() {
    // console.log('user', this.user.ClientLinkedinMetaDatum?.profile_image);
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  navigateTo(location: string): void {
    this.router.navigate([`/${location}`], { relativeTo: this.route });
  }

  onLogout() {
    this.store.dispatch(LeadListActions.resetLeadLists());
    this.store.dispatch(MessageTemplateActions.resetMessageTemplates());
    this.store.dispatch(CampaignsActions.resetCampaigns());
    this.store.dispatch(AuthActions.logOut());
  }

}
