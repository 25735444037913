<div class="card-wrapper" *ngIf="!!link">
  <div class="card-slider">
    <div class="content">
      <profile-image
        style="position: absolute; left: -5px; padding: 5px"
        [image]="image"
        [fullName]="name"
      ></profile-image>
      <div class="details">
        <div class="flex__center--start">
          <span class="name">{{ name }}</span>
          <app-badge
            [size]="'small'"
            [withIcon]="false"
            *ngIf="sentiment"
            [text]="sentiment"
            [variant]="sentiment === 'Interested' ? 'filled' : 'danger'"
          ></app-badge>
        </div>
        <p class="description">{{ description }}</p>
      </div>
    </div>
    <a
      rel="noreferrer"
      target="_blank"
      href="{{ link }}"
      class="cta-link"
      title="View Linkedin Profile"
    >
      <img
        src="{{ arrowRightIcon }}"
        alt="arrow-right"
        draggable="false"
        style="width: 24px"
      />
    </a>
  </div>
</div>
