import { createReducer, on } from '@ngrx/store';
import * as ProfileActivityActions from './profile-activity.actions';

export interface ProfileActivityState {
  interactedProfiles: any[];
  activeProfileIndex: number;
  isInviteInProgress: boolean;
}

export const initialState: ProfileActivityState = {
  interactedProfiles: [],
  activeProfileIndex: 0,
  isInviteInProgress: false,
};

export const profileActivityReducer = createReducer(
  initialState,

  // Add interacted profile
  on(ProfileActivityActions.addInteractedProfile, (state, { profile }) => ({
    ...state,
    interactedProfiles: [...state.interactedProfiles, profile]
  })),

  // Clear interacted profiles
  on(ProfileActivityActions.clearInteractedProfiles, (state) => ({
    ...state,
    interactedProfiles: [],
    activeProfileIndex: 0,
    isInviteInProgress: false,
  })),

  // Navigate to the next profile
  on(ProfileActivityActions.nextProfile, (state) => ({
    ...state,
    activeProfileIndex: state.activeProfileIndex < state.interactedProfiles.length - 1
      ? state.activeProfileIndex + 1
      : state.activeProfileIndex
  })),

  // Navigate to the previous profile
  on(ProfileActivityActions.previousProfile, (state) => ({
    ...state,
    activeProfileIndex: state.activeProfileIndex > 0
      ? state.activeProfileIndex - 1
      : state.activeProfileIndex
  })),

  // Start the connection invite process
  on(ProfileActivityActions.startConnectionInviteProcess, (state) => ({
    ...state,
    isInviteInProgress: true
  })),

  // Stop the connection invite process
  on(ProfileActivityActions.stopConnectionInviteProcess, (state) => ({
    ...state,
    isInviteInProgress: false
  })),

  // Complete the connection invite process
  on(ProfileActivityActions.completeConnectionInviteProcess, (state) => ({
    ...state,
    isInviteInProgress: false
  }))
);

export const profileActivityFeatureKey = 'profileActivity';

export const profileActivityFeatureState = (state: any) => state[profileActivityFeatureKey];
