import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any',
})
export class ProfileHelperService {
  constructor() {}

  getProfileInitials(name: string): string | void {
    if (!name) return;

    const specialChars = /[!@#$%^&*()_+\-=\[\]{};':"\\|“,.<>\/?]+/;
    const nameArray = name.split(' ');

    if (specialChars.test(nameArray[0]))
      nameArray[0] = nameArray[0].replace(specialChars, '');
    if (nameArray[1] && specialChars.test(nameArray[1]))
      nameArray[1] = nameArray[1].replace(specialChars, '');

    const initials = nameArray
      .map((name) => name[0])
      .join('')
      .toUpperCase();

    if (initials.length > 2) {
      return initials.slice(0, 2);
    }
    return initials;
  }
}
