import { MessageTemplate } from '../message-templates/message-template.model';
import { ApiOperation } from './helpers/api-operation';
import { createPipaStore } from './store.helpers';
type PartialTemplate = Partial<MessageTemplate>
interface AssociateTemplateState {
  associateTemplate: ApiOperation
  campaignId: any
  newTemplate?: PartialTemplate
  newTemplateError?: any
  setNewTemplate: (newTemplate: PartialTemplate) => void
  setCampaignId: (id: number) => void
}

export const useTemplatesStore = (() => createPipaStore<AssociateTemplateState>((set, get) => ({
  associateTemplate: new ApiOperation('associateTemplate', set),
  campaignId: null,
  newTemplate: null,
  newTemplateError: null,
  setNewTemplate: (template) => set({ newTemplate: template }),
  setCampaignId: (id) => set({ campaignId: id })
})))()
