<div
  *ngIf="!routesHidden.includes(activeRoute)"
  class="side-nav-container"
  [ngClass]="{ 'bottom-nav': isMobile }"
>
  <ul class="side-nav-button-wrapper">
    <app-nav-cell
      (click)="navigate('dashboard')"
      text="Home"
      icon="{{ activeRoute === routes.dashboard ? homeActiveIcon : homeIcon }}"
      activeClass="{{
        activeRoute === routes.dashboard ? 'nav__active' : null
      }}"
    ></app-nav-cell>
    <app-nav-cell
      (click)="navigate('campaigns')"
      text="Campaigns"
      icon="{{
        activeRoute === routes.campaigns ? campaignActiveIcon : campaignIcon
      }}"
      activeClass="{{
        activeRoute === routes.campaigns ? 'nav__active' : null
      }}"
    ></app-nav-cell>
    <app-nav-cell
      (click)="navigate('leads/create-lead-lists')"
      text="Leads"
      icon="{{ activeRoute === routes.leads ? leadsActiveIcon : leadsIcon }}"
      activeClass="{{ activeRoute === routes.leads ? 'nav__active' : null }}"
    ></app-nav-cell>
    <app-nav-cell
      [text]="isMobile ? 'Templates' : 'Message Templates'"
      (click)="navigate('message-templates')"
      icon="{{
        activeRoute === routes.messageTemplates
          ? templateActiveIcon
          : templateIcon
      }}"
      activeClass="{{
        activeRoute === routes.messageTemplates ? 'nav__active' : null
      }}"
    ></app-nav-cell>
    <ng-container *ngIf="isMobile">
      <app-nav-cell
        text="Settings"
        (click)="navigate('user-profile')"
        icon="{{
          activeRoute === routes.userProfile ? settingsActiveIcon : settingsIcon
        }}"
        activeClass="{{
          activeRoute === routes.userProfile ? 'nav__active' : null
        }}"
      ></app-nav-cell>
    </ng-container>
  </ul>
</div>
