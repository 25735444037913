import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestHelpersService } from '../shared/services/request-helpers.service';
import { HttpClient } from '@angular/common/http';

export interface AuthResponseData {
  access_token: string;
}

export interface SignInCredentials {
  email: string;
  password: string;
}

interface SignUpData {
  name: string;
  email: string;
  password: string;
  phone_number: string;
  accepted_terms: boolean;
  phone_country_code: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private signInUrl: string = `${environment.apiUrl}/auth/token`;
  private signUpUrl: string = `${environment.apiUrl}/auth/signup`;
  private headers = this.requestHelpers.createHeaders();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService
  ) {}

  authSignUp(data: SignUpData) {
    return this.http.post<AuthResponseData>(this.signUpUrl, {
      full_name: data.name,
      phone_number: data.phone_number,
      email: data.email,
      password: data.password,
      accepted_terms: data.accepted_terms,
      phone_country_code: data.phone_country_code,
      ...this.headers,
    });
  }

  authLogin(credentials: SignInCredentials) {
    return this.http.post<AuthResponseData>(this.signInUrl, {
      email: credentials.email,
      password: credentials.password,
      grant_type: 'client_credentials',
    });
  }
}
