import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ClickOutsideDirective } from 'src/app/shared/directives/click-outside.directive';
import * as fromRoot from '../../../store/app.reducer';
import * as AuthActions from '../../../auth/store/auth.actions';

@Component({
  selector: 'app-account-menu',
  template: `
    <div
      class="account_menu_wrapper"
      (click)="toggleDropdown()"
      (clickOutside)="dropdownOpen = false"
    >
      <div class="account_menu_icon">
        <img
          src="assets/icons/person.svg"
          draggable="false"
          alt="account menu icon"
        />
      </div>
      <div class="{{ dropdownOpen ? 'dropdown_list' : 'drop_down_inactive' }}">
        <li class="menu-item user-name">{{ userName }}</li>
        <li class="menu-item" (click)="navigateTo('user-profile')">
          Account Details
        </li>
        <li class="menu-item" (click)="onLogout()">Logout</li>
      </div>
    </div>
  `,
  standalone: true,
  imports: [ClickOutsideDirective],
  styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
  dropdownOpen: boolean = false;
  @Input() onNavClick: any;
  @Input() userName: string;
  popoverArrow: string = 'assets/icons/popover-arrow.svg';
  privacyUrl = 'https://pipa.cloud/privacy';
  termsUrl = 'https://pipa.cloud/terms';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.AppState>
  ) {}

  ngOnInit() {}

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  navigateTo(location: string): void {
    this.router.navigate([`/${location}`], { relativeTo: this.route });
  }

  onLogout() {
    this.store.dispatch(AuthActions.logOut());
  }
}
