
export const ConvertStringToMoney = (value: string) => {
  const lastTwoIndex = value.length - 2;
  const cents = value.substring(lastTwoIndex);
  const dollars = value.substring(0, lastTwoIndex);
  const nf = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const fomattedDollar = nf.format(Number(dollars));
  return fomattedDollar + '.' + cents;
};

export const FormatPhoneNumber = (phoneNumberString: string) => {
  let phoneNumber = phoneNumberString;
  const formatPhoneNumber = phoneNumber?.replace(
    /(\d{3})(\d{3})(\d{4})/,
    '($1) $2-$3'
  );
  return formatPhoneNumber;
};

export const CleanPhoneNumber = (phoneNumberString: string) => {
  let phoneNumber = phoneNumberString;
  const cleanPhoneNumber = phoneNumber?.replace(/[^0-9]/g, '');
  return cleanPhoneNumber;
};

export const FormatToPercentage = (num) => {
  return (num * 100).toFixed(0) + '%';
};
