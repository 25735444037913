import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { useToastStore } from 'src/app/store/toast.store';

@Component({
  selector: 'app-toast',
  standalone: true,
  imports: [NgbToastModule, NgIf],
  template: `
		<ngb-toast
      *ngIf="isVisible"
			[class]="(variant === 'danger') ? 'bg-danger text-light' : 'bg-success text-light'"
			[autohide]="true"
			[delay]="delay || 5000"
		>
      <div class="flex__row--space-between toast-content-wrapper">
        <div class="flex">
          <div class="context-icon" style="margin-right: 10px">
          <img class="danger-icon" *ngIf="variant === 'danger'" draggable="false" [src]="dangerIcon" alt="danger icon" style="width: 18px" />
          <img class="success-icon" *ngIf="variant === 'success'" draggable="false" [src]="sucessIcon" alt="success" style="width: 18px" />
          </div>
          <span class="toast-content">{{ content }}</span>
        </div>
          <img class="close-icon" (click)="hide()" draggable="false" [src]="closeIcon" alt="close" style="width: 24px; cursor: pointer" />
      </div>
		</ngb-toast>
	`,
  host: { class: 'toast-container position-fixed top-0 end-0 p-2', style: 'z-index: 999' },
})
export class ToastComponent {
  closeIcon = 'assets/icons/x-close.svg';
  dangerIcon = 'assets/icons/circle-exclamation.svg';
  sucessIcon = 'assets/icons/circle-check-outline.svg';
  @Input() isVisible: boolean = false;
  delay: number = useToastStore.getState().delay;
  content: string = useToastStore.getState().content;
  variant: string = useToastStore.getState().variant;

  constructor() {
    useToastStore.subscribe((state) => {
      this.isVisible = state.isVisible;
      this.content = state.content;
      this.variant = state.variant;
      this.delay = state.delay;
      if (this.isVisible) {
        setTimeout(() => {
          this.hide();
        }, this.delay);
      }
    });
  }

  hide(): void {
    useToastStore.setState({ isVisible: false, content: '', variant: 'success', delay: this.delay });
  }

}
