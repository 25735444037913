<div
  class="profile-image-wrapper {{ variant }} profile-image-wrapper__{{ size }}"
>
  <img
    *ngIf="image; else fallbackImage"
    class="profile-img"
    [src]="image"
    alt="profile-image"
    draggable="false"
  />
  <ng-template #fallbackImage>
    <span *ngIf="!image" class="default-img {{ variant }}">{{
      getProfileInitials(fullName)
    }}</span>
  </ng-template>
</div>
