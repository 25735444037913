<div class="profile-image-wrapper {{ variant }}">
  <img
    *ngIf="image"
    class="profile-img"
    [src]="image"
    alt="profile-image"
    draggable="false"
  />
  <span *ngIf="!image" class="default-img {{ variant }}">{{
    getProfileInitials(fullName)
  }}</span>
</div>
