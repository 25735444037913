export const GetDaysUntil = (dateString) => {
  const futureDate = new Date(dateString); // Convert the date string to a Date object
  const currentDate = new Date(); // Get the current date and time

  // Calculate the difference in milliseconds
  const diffInMilliseconds = futureDate.getTime() - currentDate.getTime();

  // Convert milliseconds to days
  const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

  return diffInDays;
};

export const HasDatePassed = (dateString) => {
  const givenDate = new Date(dateString);
  const currentDate = new Date();

  // Set the time of the current date to 00:00:00 for a fair comparison
  currentDate.setHours(0, 0, 0, 0);

  // Compare the given date with the current date
  return givenDate < currentDate;
};
