import { Component, Input } from '@angular/core';
import { CampaignsService } from 'src/app/campaigns/services/campaigns.api.service';
import {
  AnalyticsManagerService,
  Events,
} from 'src/app/shared/services/analytics-manager.service';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';

@Component({
  selector: 'download-csv',
  templateUrl: './download-csv.component.html',
  styleUrl: './download-csv.component.scss',
})
export class DownloadCsvComponent {
  @Input() isVisible: boolean = true;
  @Input() campaignId: number;
  @Input() campaignName: string;

  constructor(private campaignService: CampaignsService) {
    this.isVisible = useModalManagerStore.getState().downloadCsv;
  }

  onCloseModalClick = () => {
    useModalManagerStore.setState({
      downloadCsv: false,
    });
  };

  onDownloadCsvClick = () => {
    AnalyticsManagerService.recordButtonClick(Events.downloadCSVClicked);
    this.campaignService
      .getCampaignCsv(this.campaignId)
      .subscribe((res: any) => {
        const blob = new Blob([res], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.campaignName}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    this.onCloseModalClick();
  };

  ngOnInit(): void {
    useModalManagerStore.subscribe(
      (s) => s.downloadCsv,
      (newState) => {
        this.isVisible = newState;
      }
    );
  }
}
