<div
  class="nugget-container flex__column--center"
  [ngClass]="{ dark: theme === 'dark' }"
>
  <ng-container
    *skeleton="
      isLoading;
      repeat: 1;
      className: 'skeleton-sm-mb';
      height: '10px';
      width: '50px';
      borderRadius: '30px'
    "
  >
    <span
      class="stat-value"
      *ngIf="value !== null && value !== undefined; else noValue"
      >{{ value }}</span
    >
    <ng-template #noValue>
      <span class="stat-value">---</span>
    </ng-template>
  </ng-container>
  <ng-container
    *skeleton="
      isLoading;
      repeat: 1;
      className: 'skeleton-md';
      height: '10px';
      width: '110px';
      borderRadius: '30px'
    "
  >
    <span class="stat-label">{{ label }}</span>
  </ng-container>
</div>
