<form class="input-form" [formGroup]="searchForm">
  <app-input-field
    class="debounce-search-input"
    [theme]="theme"
    formControlName="searchString"
    [placeholder]="placeholder"
    [useErrorMessage]="false"
    (keyup)="onKeyup($event)"
    (keydown)="onKeydown($event)"
  ></app-input-field>
</form>

<ul
  *ngIf="isVisible"
  class="search-results scrollable-popover"
  (clickOutside)="clearResults()"
>
  <span
    class="list-category"
    *ngIf="listCategory && searchResults.length > 0"
    >{{ listCategory }}</span
  >
  <li
    class="flex search-result-item"
    *ngFor="let item of searchResults"
    (click)="handleItemClick(item)"
  >
    <profile-image
      *ngIf="displayConfig?.imageProperty"
      [image]="getProperty(item, displayConfig.imageProperty)"
      [fullName]="getProperty(item, displayConfig.primaryText)"
      size="medium"
      class="result-image"
    ></profile-image>
    <div
      *ngIf="displayConfig"
      [ngClass]="{ 'flex__column--start': !!displayConfig?.secondLineText }"
    >
      <div>
        {{ getProperty(item, displayConfig.primaryText) }}
        <span
          *ngIf="displayConfig?.secondaryText"
          class="result-secondary-text"
        >
          • {{ getProperty(item, displayConfig.secondaryText) }}
        </span>
      </div>
      <div
        *ngIf="!!displayConfig?.secondLineText"
        class="result-secondary-text"
      >
        {{ getProperty(item, displayConfig.secondLineText) }}
      </div>
    </div>
    <span *ngIf="!displayConfig"> {{ formatResults(item) }} </span>
  </li>
  <div
    *ngIf="
      searchResults.length === 0 &&
      searchForm.get('searchString').value.length > 2
    "
    class="no-results"
  >
    No results found.
  </div>
</ul>
