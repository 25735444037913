import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap, catchError, Observable, map, throwError } from 'rxjs';
import { RequestHelpersService } from 'src/app/shared/services/request-helpers.service';
import { useLinkedinConnectionsStore } from 'src/app/store/linkedin-connections';
import { environment } from 'src/environments/environment';
import { useToastStore } from 'src/app/store/toast.store';
import {
  AnalyticsManagerService,
  Events,
} from 'src/app/shared/services/analytics-manager.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LinkedinConnectionsService {
  private createHeaders = () => this.requestHelpers.createHeaders();
  private baseUrl: string = `${environment.apiUrl}/linkedin-connections`;
  private activityUrl: string = `${environment.apiUrl}/connection-activity`;
  private conversationUrl: string = `${environment.apiUrl}/conversation`;

  linkedinConnectionsStore = useLinkedinConnectionsStore.getState();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService,
    private router: Router
  ) { }

  getLinkedinConnections = (
    campaignId: any,
    page: number | string,
    pageSize?: number | string,
    status?: string,
    searchString?: string,
    byReplies?: boolean
  ) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    this.linkedinConnectionsStore.setCampaignId(campaignId);
    const clientId = this.requestHelpers.getClientId();
    let params = new HttpParams();
    params = params.append('client_id', clientId);
    params = params.append('marketing_campaign_id', campaignId);
    params = params.append('pageSize', pageSize);
    params = params.append('page', page);
    if (status) params = params.append('status', status);
    if (searchString) params = params.append('searchString', searchString);
    if (byReplies && byReplies !== null)
      params = params.append('byReplies', byReplies);

    const request$ = this.http
      .get(`${this.baseUrl}`, {
        params: params,
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  };

  getLinkedinRawConnections = (searchString: string) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const clientId = this.requestHelpers.getClientId();
    let params = new HttpParams();
    params = params.append('client_id', clientId);
    params = params.append('searchString', searchString);
    params = params.append('pageSize', 20);
    params = params.append('page', 0);

    const request$ = this.http
      .get(`${this.baseUrl}/search-raw-connections`, {
        params: params,
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  }


  getLinkedinConnectionActivity = (id: any) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const url = this.activityUrl + `?connectionId=`;
    const request$ = this.http
      .get(`${url}${id}`, { headers: this.createHeaders() })
      .pipe(
        tap((resData) => {
          // console.log('linkedin connection activity', resData);
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  };

  getResolvedReasonsByCampaigns = (campaignId: any) => {
    this.linkedinConnectionsStore.resolvedReasonsApiOperation.startLoading();
    const request$ = this.http
      .get(`${this.baseUrl}/resolved-reasons?campaignId=${campaignId}`, {
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.resolvedReasonsApiOperation.succeeded();
            this.linkedinConnectionsStore.setResolvedReasons(
              resData['resolved_reason']
            );
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.resolvedReasonsApiOperation.failed(
            errorRes
          );
          return errorRes;
        })
      );
    return request$;
  };

  getConnectionAnalytics = (period: string) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const request$ = this.http
      .get(`${this.baseUrl}/analytics?period=${period}`, {
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  }

  updateLinkedinConnection = (id: any, data: any) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const request$ = this.http
      .put(`${this.baseUrl}/${id}`, data, { headers: this.createHeaders() })
      .pipe(
        tap((resData) => {
          if (
            !resData.hasOwnProperty('errors') &&
            resData['message'] === 'Success'
          ) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  };

  sendMessage = (
    message: string,
    connectionId: number,
    numMessagesSent: number,
    prospectName?: string
  ) => {
    prospectName = prospectName.split(' ')[0] || prospectName || 'Prospect';
    this.linkedinConnectionsStore.sendMessageApiOperation.startLoading();
    const payload = { message, channel: 'profile' };
    const request$ = this.http
      .post(`${this.conversationUrl}/${connectionId}/send-message`, payload, {
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          // console.log('send message response', resData);
          if (
            !resData.hasOwnProperty('errors') &&
            resData['message'] === 'Success'
          ) {
            this.linkedinConnectionsStore.sendMessageApiOperation.succeeded();
            useLinkedinConnectionsStore.setState({
              connectionActivity: [
                ...useLinkedinConnectionsStore.getState().connectionActivity,
                {
                  activityType: `You sent a message to ${prospectName}`,
                  message: message,
                  icon: 'assets/icons/chat-text.svg',
                  createdAt: new Date().toISOString(),
                },
              ],
            });
            const newNumMessagesSent = numMessagesSent + 1;
            const updateConnectionPayload = {
              number_of_messages_sent: newNumMessagesSent,
            };
            this.updateLinkedinConnection(
              connectionId,
              updateConnectionPayload
            ).subscribe((res) => {
              // console.log('update connection response', res);
            });
          }
        }),
        catchError((errorRes) => {
          AnalyticsManagerService.recordError(
            Events.sendMessageError,
            errorRes
          );
          this.linkedinConnectionsStore.sendMessageApiOperation.failed(
            errorRes
          );
          return errorRes;
        })
      );
    return request$;
  };

  markConnectionAsComplete = (id: any, details: any) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const request$ = this.http
      .post(`${this.baseUrl}/mark-completed/${id}`, details, {
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
            const connectionActivity =
              useLinkedinConnectionsStore.getState().connectionActivity;
            useLinkedinConnectionsStore.setState({
              connectionActivity: [
                ...connectionActivity,
                {
                  activityType: details.details,
                  icon: 'assets/icons/resolved-shield.svg',
                  createdAt: new Date().toISOString(),
                },
              ],
            });
            useLinkedinConnectionsStore.setState({
              activeConnection: [
                {
                  ...resData['connection'],
                },
              ],
            });
          } else {
            useToastStore.setState({
              isVisible: true,
              content: 'Opps something went wrong, please try again',
              variant: 'danger',
            });
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  };

  markConnectionAsActive = (id: any, details: any) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const request$ = this.http
      .post(`${this.baseUrl}/mark-active/${id}`, details, {
        headers: this.createHeaders(),
      })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
            const connectionActivity =
              useLinkedinConnectionsStore.getState().connectionActivity;
            useLinkedinConnectionsStore.setState({
              connectionActivity: [
                ...connectionActivity,
                {
                  activityType: details.details,
                  icon: 'assets/icons/lightening-bolt.svg',
                  createdAt: new Date().toISOString(),
                },
              ],
            });
            useLinkedinConnectionsStore.setState({
              activeConnection: [
                {
                  ...resData['connection'],
                },
              ],
            });
          } else {
            useToastStore.setState({
              isVisible: true,
              content: 'Opps something went wrong, please try again',
              variant: 'danger',
            });
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$;
  };

  deleteLinkedinConnection = (id: any) => {
    this.linkedinConnectionsStore.linkedinConnections.startLoading();
    const request$ = this.http
      .delete(`${this.baseUrl}/${id}`, { headers: this.createHeaders() })
      .pipe(
        tap((resData) => {
          if (!resData.hasOwnProperty('errors')) {
            this.linkedinConnectionsStore.linkedinConnections.succeeded();
          }
        }),
        catchError((errorRes) => {
          this.linkedinConnectionsStore.linkedinConnections.failed(errorRes);
          return errorRes;
        })
      );
    return request$.subscribe();
  };

  sendConnectionInvite(publicId: string, messageBody: string, profileUrn: string, campaignId: number): Observable<any> {
    const body = { publicId, messageBody, profileUrn, campaignId };
    return this.http.post(`${this.baseUrl}/send-connection-request`, body, {
      headers: this.createHeaders(),
    }).pipe(
      map((response: any) => {
        return response; // Handle the success response
      }),
      catchError((error) => {
        if (error.status === 401) {
          // Redirect to login page or session expiry page on 401
          // this.router.navigate(['/session-expired']);
          return throwError(() => new Error('SESSION_EXPIRED'));
        } else if (error.status === 422) {

          return throwError(() => new Error('INVALID_PARAMS'));
        } else if (error.status === 400) {
          return throwError(() => new Error('RATE_LIMIT'));
        }
        else {
          return throwError(() => new Error('INVALID'));
        }
      })
    );
  }
}
