import { ActionReducerMap } from '@ngrx/store';

import * as fromAuth from '../auth/store/auth.reducer';
import * as fromCampaigns from '../store/campaigns/campaigns.reducer';
import * as fromMessageTemplates from '../store/message-templates/message-templates.reducer';
import * as fromUser from '../store/user/user.reducer';
import * as fromLeadList from '../store/lead-list/lead-list.reducer';
import * as fromLinkedinConnectionRequest from './linkedin-connection-request/linkedin-connection-request.reducer';
import * as fromLinkedinProfileActivity from './linkedin-profile-activity/linkedin-profile-activity.reducer';

export interface AppState {
  auth: fromAuth.State;
  campaigns: fromCampaigns.State;
  messageTemplates: fromMessageTemplates.State;
  user: typeof fromUser.initialState;
  leadLists: typeof fromLeadList.initialState;
  linkedinConnectionRequest: typeof fromLinkedinConnectionRequest.initialState;
  linkedinProfileActivity: typeof fromLinkedinProfileActivity.initialState;
}

export const appReducer: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  campaigns: fromCampaigns.campaignsReducer,
  messageTemplates: fromMessageTemplates.messageTemplatesReducer,
  user: fromUser.userReducer,
  leadLists: fromLeadList.leadListReducer,
  linkedinConnectionRequest: fromLinkedinConnectionRequest.linkedinConnectionRequestReducer,
  linkedinProfileActivity: fromLinkedinProfileActivity.linkedinProfileActivityReducer,
}
