import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RequestHelpersService } from '../shared/services/request-helpers.service';
import { UserState as User, useUserStore } from '../store/user.store';
import { useToastStore } from '../store/toast.store';
import { Subject, catchError, map, take, takeUntil } from 'rxjs';
// import { User } from '../auth/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  private url: string = `${environment.apiUrl}/clients`;
  private createHeaders = () => this.requestHelpers.createHeaders();
  private setUser = useUserStore.getState().setUser;
  private isDestroyed$ = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService
  ) { }

  getUserProfile() {
    return new Promise<User>((resolve, reject) => {
      const id = this.requestHelpers.getClientId();
      const response$ = this.http
        .get(`${this.url}/${id}`, { headers: this.createHeaders() })
        .pipe(
          map((user: User) => {
            return user;
          }),
          catchError(async (e) => {
            this.handleError(e);
            reject(e);
          })
        );
      response$.pipe().subscribe((user: User) => {
        this.setUser(user);
        resolve(user);
      });
    });
  }

  updateUserProfile(
    userProfile: User,
    toastContent?: string,
    showSuccess = true
  ) {
    toastContent = toastContent || 'Profile updated successfully.';
    const id = this.requestHelpers.getClientId();
    const response$ = this.http
      .put(`${this.url}/${id}`, userProfile, { headers: this.createHeaders() })
      .pipe(catchError(async (e) => this.handleError(e)));
    response$.pipe().subscribe((user: User) => {
      if (user) {
        this.getUserProfile();
        if (showSuccess)
          useToastStore.setState({
            isVisible: true,
            content: `${toastContent}`,
            variant: 'success',
          });
      }
    });
  }

  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
    }
    if (err.status === 409) {
      useToastStore.setState({
        isVisible: true,
        content: `Email already in use.`,
        variant: 'danger',
      });
    } else {
      useToastStore.setState({
        isVisible: true,
        content: `Opps looks like an error occurred.`,
        variant: 'danger',
      });
    }
  }

}
