import { Component, Input } from '@angular/core';
import { drawerAnimation } from '../animations/drawerAnimation';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
  animations: [drawerAnimation]
})
export class DrawerComponent {
  @Input() isOpen: boolean = false;

  constructor() { }

  toggleDrawer() {
    this.isOpen = !this.isOpen;
  }
}
