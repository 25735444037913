import { RequestHelpersService } from "../shared/services/request-helpers.service";

export class User {
  constructor(
    private _token: string,
    public email?: string,
    public password?: string,
    public phone_number?: string,
    private requestHelpers?: RequestHelpersService,
  ) { }

  get token() {
    if (this.requestHelpers.isJwtExpired()) {
      return null;
    }
    return this._token;
  }

  get isAuthenticated() {
    return !!this.token;
  }

}
