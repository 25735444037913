import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { helpContent } from 'src/app/constants/constants';
import { Store } from '@ngrx/store';
import { OnboardingHelperService } from '../services/onboarding-helper.service';
import { PipaTourService } from '../services/pipa-tour.service';
import * as fromRoot from 'src/app/store/app.reducer';
import * as CampaignsActions from 'src/app/campaigns/store/campaigns.actions';
import { UserState, useUserStore } from 'src/app/store/user.store';

@Component({
  selector: 'message-template-options',
  template: `
    <div class="message-options-container">
      <div class="flex__row--space-between switch-block">
        <span class="switch-label s5"
          >Send message with connection
          <app-tooltip
            content="{{ messageBeforeConnectTooltip }}"
            [id]="'message-before-connect__switch'"
          >
            <img
              src="{{ infoIcon }}"
              alt="info"
              class="info-icon"
              draggable="false"
            />
          </app-tooltip>
        </span>
        <app-switch
          (click)="onSwitchChange($event)"
          [checked]="isConnectWithMessage"
          [size]="'large'"
          id="connect_with_message"
        ></app-switch>
      </div>
      <div class="flex__row--space-between switch-block">
        <span class="switch-label s5"
          >Send message after connecting
          <app-tooltip
            content="{{ messageAfterConnectTooltip }}"
            [id]="'message-after-connect__switch'"
          >
            <img
              src="{{ infoIcon }}"
              alt="info"
              class="info-icon"
              draggable="false"
            />
          </app-tooltip>
        </span>
        <app-switch
          (click)="onSwitchChange($event)"
          [checked]="isMessageAfterConnect"
          [size]="'large'"
          id="message_after_connection"
        ></app-switch>
      </div>
      <div *ngIf="isProfessional" class="flex__row--space-between switch-block">
        <span class="switch-label s5"
          >Include drip campaign
          <app-tooltip
            content="{{ dripCampaignTooltip }}"
            [id]="'drip__switch'"
          >
            <img
              src="{{ infoIcon }}"
              alt="info"
              class="info-icon"
              draggable="false"
            />
          </app-tooltip>
        </span>
        <app-switch
          (click)="onSwitchChange($event)"
          [checked]="isDripCampaign"
          [size]="'large'"
          id="include_drip_campaign"
        ></app-switch>
      </div>
    </div>
  `,
  styleUrls: ['./message-template-options.component.scss'],
})
export class MessageTemplateOptionsComponent implements OnInit {
  @Input() isDripCampaign: boolean;
  @Input() isConnectWithMessage: boolean;
  @Input() isMessageAfterConnect: boolean;
  @Input() campaignId: number;
  @Input() addMode: boolean;
  @Output() onChange = new EventEmitter<any>();
  @Input() isProfessional: boolean;
  user?: UserState;
  isNewUserCampaignFlow: boolean;
  dripCampaignTooltip = helpContent.DRIP_CAMPAIGN;
  messageBeforeConnectTooltip = helpContent.MESSAGE_BEFORE_CONNECT;
  messageAfterConnectTooltip = helpContent.MESSAGE_AFTER_CONNECTION;
  infoIcon = 'assets/icons/info.svg';

  constructor(
    private store: Store<fromRoot.AppState>,
    private onboardingHelperService: OnboardingHelperService,
    private pipaTourService: PipaTourService
  ) {}

  onSwitchChange = (e) => {
    if (this.addMode) {
      this.onChange.emit(e);
    } else {
      let updatedCampaign = {};
      if (e.target.id === 'include_drip_campaign') {
        updatedCampaign = {
          id: this.campaignId,
          include_drip_campaign: !this.isDripCampaign,
        };
        this.store.dispatch(
          CampaignsActions.updateCampaign({ campaign: updatedCampaign })
        );
      } else if (e.target.id === 'connect_with_message') {
        updatedCampaign = {
          id: this.campaignId,
          connect_with_message: !this.isConnectWithMessage,
        };
        this.store.dispatch(
          CampaignsActions.updateCampaign({ campaign: updatedCampaign })
        );
      } else if (e.target.id === 'message_after_connection') {
        updatedCampaign = {
          id: this.campaignId,
          message_after_connection: !this.isMessageAfterConnect,
        };
        this.store.dispatch(
          CampaignsActions.updateCampaign({ campaign: updatedCampaign })
        );
      }
    }
  };

  ngOnInit(): void {
    // this.user = useUserStore.getState();
    // this.isProfessional = this.user?.Product?.allow_professional;
    // console.log('isProfessional', this?.isProfessional, this.user)
  }

  ngAfterViewInit(): any {
    this.isNewUserCampaignFlow =
      this.onboardingHelperService.isNewUserCampaignFlow();
    // console.log('isNewUserCampaignFlow', this.isNewUserCampaignFlow)
    if (this.isNewUserCampaignFlow && this.isProfessional) {
      this.pipaTourService.showCampaignTour();
    }
  }
}
