<div *ngIf="isOpen" class="drawer" @drawerAnimation>
  <div class="drawer-content">
    <div class="flex__row--end">
      <img
        src="assets/icons/x-close-black.svg"
        class="_close-icon"
        alt="close-icon"
        (click)="toggleDrawer()"
      />
    </div>
    <ng-content></ng-content>
  </div>
</div>
