import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as LeadListActions from './lead-list.actions';
import { LeadListsContract } from './lead-list.interface';

export interface LeadListState {
  leadLists: any[];
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingFailure: boolean;
  error: any;
}

export const initialState: LeadListState = {
  leadLists: [],
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingFailure: false,
  error: null,
};

export const leadListReducer = createReducer(
  initialState,

  // Handle fetching of lead lists
  on(LeadListActions.fetchLeadListsStart, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),

  // Handle successful fetch of lead lists
  on(LeadListActions.fetchLeadListsSuccess, (state, { leadLists }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null,
    leadLists,
  })),

  // Handle failed fetch of lead lists
  on(LeadListActions.fetchLeadListsFail, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error,
  })),

  // Handle adding a new lead list
  on(LeadListActions.addLeadListStart, (state) => ({
    ...state,
    isLoading: true,
    isLoadingSuccess: false,
    isLoadingFailure: false,
    error: null,
  })),

  // Handle failed adding a new lead list
  on(LeadListActions.addLeadListFail, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: false,
    isLoadingFailure: true,
    error,
  })),

  // Handle adding a new lead list
  on(LeadListActions.addLeadListSuccess, (state, { leadList }) => ({
    ...state,
    isLoading: false,
    isLoadingSuccess: true,
    isLoadingFailure: false,
    error: null,
    leadLists: [...state.leadLists, leadList],
  })),

  // Handle resetting lead lists
  on(LeadListActions.resetLeadLists, () => ({
    ...initialState,
  }))
);

export const leadListFeatureKey = 'leadLists';

export const leadListFeatureState = createFeatureSelector<LeadListsContract>(leadListFeatureKey);
