export const CampaignTypes = {
  STANDARD: 1,
  SALES_NAVIGATOR: 2,
  CLOUD: 3,
};

export const helpContent = {
  NO_ACTIVE_CAMPAIGN_PROMPT:
    'You must have an active subscription to start campaigns.',
  DRIP_CAMPAIGN:
    "Enabling a drip campaign will activate a series of automated messages if your connection hasn't engaged in conversation. First drip message: 12 days after connecting. Second drip message: 12 days after the first drip message. Final drip message: 12 days after the second drip message.",
  MESSAGE_BEFORE_CONNECT:
    'Include a short message with your connection request. Max length is 275 characters. Remember $prospect can be used to greet the prospect by first name.',
  MESSAGE_AFTER_CONNECTION:
    'Send a message after your connection request is accepted. Remember $prospect can be used to greet the prospect by first name.',
  CHOOSE_TEMPLATE_PROMPT:
    'Choose a message template from the dropdown to start your campaign.',
  ADD_TEMPLATE_PROMPT:
    'You need to associate a message template before you start your campaign.',
  PASSWORD_RESET_SUCCESS:
    'Success! Please check your email for a reset password link.',
  CANCEL_PROMPT:
    'Are you sure you want to discard changes? Your changes will not be saved.',
  PIPA_SUGGEST_RATE_LIMIT_PROMPT:
    'You have exceeded the daily maximum of 30 pipa suggestions.',
  DELETE_TEMPLATE_PROMPT: 'Are you sure you want to delete this template?',
  GENERAL_ERROR:
    'oops! something went wrong. If the issue persists please contact us.',
};

export const Tooltip = {
  ADD_LINKEDIN_LEAD_LIST: `<span style="font-size: 16px; color: #516f90;">You can use your Sales Navigator lead lists to customize your target audience.</span><br/><br/>To create a new lead list: <ol>
  <li>From the Sales Navigator homepage, click <strong>Lists</strong>, and select <strong>Lead Lists</strong> or <strong>Account Lists</strong> from the dropdown that appears.</li>
  <li>From the Lead or Account Lists page, click <strong>Create lead/account</strong> list.</li>
  <li>In the box that appears, enter your <strong>List name</strong> and <strong>List description</strong>, and click <strong>Create</strong>.</li>
</ol>  `,
};

export const ErrorCodes = {
  RATE_LIMIT: 'RATE_LIMIT',
};
