import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { VariableHighlightService } from '../services/variable-highlight.service';
import { MessageTemplate } from 'src/app/message-templates/message-template.model';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  of,
  takeUntil,
} from 'rxjs';
import { messageTemplatesFeatureKey } from 'src/app/store/message-templates/message-templates.reducer';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as fromRoot from '../../store/app.reducer';

@Component({
  selector: 'message-template-list',
  templateUrl: './message-template-list.component.html',
  styleUrl: './message-template-list.component.scss',
})
export class MessageTemplateListComponent implements OnInit, OnDestroy {
  messageTemplates: MessageTemplate[];
  @Input() hasChildren: boolean;
  @Input() name: string;
  @Input() messageType: string;
  @Input() message: string;
  @Input() isSalesNavigator?: boolean = false;
  @Input() isSelectable?: boolean = true;
  @Input() isSingleTemplate?: boolean = false;
  @Input() messageTemplateId?: number;
  @Output() onTemplateClick = new EventEmitter();
  isCollapsed: boolean = true;
  readableMessageType: string;
  selectedTemplatesArray = [];
  messageTemplateListEl: ElementRef;
  redirectUrl?: any;

  private templatesSub$: Subscription;
  private readonly isDestroyed$: Subject<void> = new Subject();

  constructor(
    private highlightService: VariableHighlightService,
    private store: Store<fromRoot.AppState>,
    private router: Router
  ) { }

  objectKeys(obj: any): string[] {
    if (obj) {
      return Object.keys(obj);
    }
    return []; // Return an empty array if the input is null or undefined TODO: Export to helpers
  }

  ngOnInit(): void {
    if (this.isSingleTemplate) {
      const messageTemplateId$: Observable<number> =
        this.setMessageTemplateId();
      const messageTemplate$: Observable<MessageTemplate[]> =
        this.setSingleMessageTemplate();
      // const redirectUrl$ = this.getRedirectUrl(messageTemplate$);

      combineLatest([messageTemplate$, messageTemplateId$])
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: ([currentTemplate, messageTemplateId]) => {
            this.messageTemplateId = messageTemplateId;
            this.messageTemplates = currentTemplate;
          },
        });
    } else {
      this.templatesSub$ = this.store
        .select(messageTemplatesFeatureKey)
        .subscribe((messageTemplatesState) => {
          this.messageTemplates = messageTemplatesState?.messageTemplates;
        });
    }
  }

  ngOnDestroy(): void {
    if (this.templatesSub$) this.templatesSub$?.unsubscribe();
    this.isDestroyed$.next();
    this.isDestroyed$.complete();
  }

  navigateToTemplate(): void {
    this.router.navigate([this.redirectUrl]);
  }

  getRedirectUrl(id): string {
    return `/message-templates/${id}/edit`;
  }

  setMessageTemplateId(): Observable<number> {
    return of(this.messageTemplateId);
  }

  setTemplateNickname(): Observable<string> {
    return of(this.name);
  }

  setSingleMessageTemplate(): Observable<any> {
    this.templatesSub$ = this.store
      .select(messageTemplatesFeatureKey)
      .subscribe((messageTemplatesState: any) => {
        const messageTemplates = messageTemplatesState?.messageTemplates;
        const template = messageTemplates.find(
          (t) => t.id === +this.messageTemplateId
        );

        this.redirectUrl = this.getRedirectUrl(this.messageTemplateId);
        this.messageTemplates = [template];
        this.name = template?.nickname;
      });
    return of(this.messageTemplates);
  }

  collapsedStates: { [key: string]: boolean } = {};
  selectedTemplates: { [key: string]: boolean } = {};

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.messageTemplates && this.messageTemplates) {
    //   this.initializeCollapseStates();
    // }
    if (this.isSingleTemplate && changes.messageTemplateId) {
      const messageTemplateId$: Observable<number> =
        this.setMessageTemplateId();
      const messageTemplate$: Observable<any> = this.setSingleMessageTemplate();
      const campaignNickname$: Observable<string> = this.setTemplateNickname();

      combineLatest([messageTemplate$, messageTemplateId$, campaignNickname$])
        .pipe(takeUntil(this.isDestroyed$))
        .subscribe({
          next: ([currentTemplate, messageTemplateId, campaignNickname]) => {
            this.messageTemplateId = messageTemplateId;
            this.messageTemplates = currentTemplate;
            this.name = campaignNickname;
          },
        });
    }
  }

  initializeCollapseStates(): void {
    this.messageTemplates.forEach((template) => {
      Object.keys(template).forEach((key) => {
        if (this.isValidMessageType(key)) {
          const combinedKey = this.getCominedKey(template.nickname, key);
          this.collapsedStates[combinedKey] = true;
        }
      });
    });
  }

  getCominedKey(nickname: string, key: string): string {
    return `${nickname}-${key}`;
  }

  toggleCollapse = (nickname: string, key: string) => {
    const combinedKey = this.getCominedKey(nickname, key);
    this.collapsedStates[combinedKey] = !this.collapsedStates[combinedKey];
  };

  isValidMessageType(key: string): boolean {
    let validMessageTypes = [
      'message_before_connection',
      'first_interaction',
      'first_drip',
      'second_drip',
      'final_drip',
    ];
    // if (!this.isSalesNavigator) {
    //   validMessageTypes = ['message_before_connection', 'first_interaction'];
    // }
    return validMessageTypes.includes(key);
  }

  highlightVariable = (textBlock: string) => {
    return textBlock && this.highlightService.highlightVariable(textBlock);
  };

  findStaleItem(arr: string[]): any {
    const staleItem = this.findFirstStaleItem(arr);
    if (staleItem !== undefined) {
      return staleItem;
    } else {
    }
  }

  findFirstStaleItem(arr: string[]): string | undefined {
    const pattern = /-\w+/; // Regular expression to match hyphen and concatenated word

    const clickedTemplate = new Set<string>();

    // Iterate through the array in reverse order
    for (let i = arr.length - 1; i >= 0; i--) {
      const item = arr[i];
      const match = item.match(pattern);
      if (match && match.length > 0) {
        const matchedSubstring = match[0];
        if (clickedTemplate.has(matchedSubstring)) {
          return item; // Found a stale item
        }
        clickedTemplate.add(matchedSubstring);
      }
    }

    return undefined; // No stale items found
  }

  getMessageTemplateContent(
    key: string,
    content: string,
    nickname: string
  ): void {
    const combinedKey = this.getCominedKey(nickname, key);
    this.selectedTemplates[combinedKey] = !this.selectedTemplates[combinedKey];

    this.selectedTemplatesArray.push(combinedKey);
    let duplicateTemplate = this.findStaleItem(this.selectedTemplatesArray);
    if (duplicateTemplate) {
      this.selectedTemplates[duplicateTemplate] = false;
      this.selectedTemplatesArray = this.selectedTemplatesArray.filter(
        (m) => m !== duplicateTemplate
      );
    }

    const template = { [key]: content };
    const emptyTemplate = { [key]: '' };
    if (this.selectedTemplates[combinedKey]) {
      this.onTemplateClick.emit(template);
    } else {
      this.onTemplateClick.emit(emptyTemplate);
    }
  }

  getReadableMessageType(type) {
    for (let message of this.messageTemplates) {
      switch (message[type]) {
        case message['message_before_connection']:
          return 'Message before connection';
        case message['first_interaction']:
          return 'Message after connection';
        case message['first_drip']:
          return 'First drip message';
        case message['second_drip']:
          return 'Second drip message';
        case message['final_drip']:
          return 'Final drip message';
        default:
          return 'Message before connection';
      }
    }
  }
}
