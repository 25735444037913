import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  template: `
    <div class="form-group">
      <label class="textarea-label s5" for="{{ name }}">
        {{ label }}
        <app-tooltip [content]="tooltipText" [id]="targetElement">
          <img
            *ngIf="useTooltip"
            class="input-field--tooltip_icon"
            src="{{ infoIcon }}"
            alt="tooltip"
            draggable="false"
            style="margin-left: 0.25rem; margin-bottom: 0.25rem; width: 16px;"
          />
        </app-tooltip>
      </label>
      <textarea
        [placeholder]="placeholder"
        class="textarea-input"
        (change)="onChange($event.target.value)"
        (blur)="onTouched()"
        [disabled]="disabled"
        [value]="value"
        name="{{ name }}"
        id="{{ id }}"
        type="text"
        appTextareaAutoresize
      >
      </textarea>
      <div class="flex error-message-container">
        <span
          [ngStyle]="{ color: isError ? '#800000' : 'transparent' }"
          class="error-message"
          >{{ errorMessage }}</span
        >
        <span *ngIf="characterCount && isError" class="character-count-text"
          >({{ characterCount }} characters)</span
        >
      </div>
    </div>
  `,
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements OnInit {
  @Input() name: string = '';
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() label: string;
  @Input() placeholder: string = '';
  @Input() value;
  @Input() required: boolean = false;
  @Input() isError: boolean = false;
  @Input() errorMessage: string = 'This field is required';
  @Input() useTooltip: boolean = false;
  @Input() targetElement: string;
  @Input() tooltipText: string;
  @Input() characterCount?: any;
  infoIcon = 'assets/icons/info.svg';

  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {}

  onChange: any = () => {};
  onTouched = () => {};

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.value = value;
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }
}
