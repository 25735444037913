import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { helpContent } from 'src/app/constants/constants';
import { MessageTemplate } from 'src/app/message-templates/message-template.model';
import { messageTemplatesFeatureKey } from 'src/app/message-templates/store/message-templates.reducer';
import { useModalManagerStore } from 'src/app/store/modal-manager.store';
import { useTemplatesStore } from 'src/app/store/templates.store';
import { useToastStore } from 'src/app/store/toast.store';

import * as MessageTemplatesActions from '../../message-templates/store/message-templates.actions';
import * as CampaignsActions from '../../campaigns/store/campaigns.actions';
import * as fromRoot from '../../store/app.reducer';

@Component({
  selector: 'templates-modal',
  template: `
  <app-modal [visible]="visible" [useActionBar]="true" header="Activate Campaign">
    <action-bar actions [useSubmit]="false" (onEmitCancel)="onClose()"></action-bar>
    <div class="templates-modal-wrapper">
      <!-- <app-card-header title="Activate Campaign"></app-card-header> -->
      <p class="flex help-block associate-template">{{messageTemplates.length ? chooseTemplatePrompt : addMessageTemplatePrompt}}</p>
      <app-dropdown
        *ngIf="messageTemplates?.length"
        (change)="handleValueChange($event)"
        [options]="messageTemplatesOptions"
        name="messageTemplateId"
        id="messageTemplate-modal-dropdown"
        label="Choose a message template">
      </app-dropdown>
      <edit-templates-form
        (onSaveTemplateClick)="onSave()"
        (onCloseClick)="onClose()"
        [isVisible]="visible"
        [messageTemplateId]="templatesDropdown.value"
        [messageTemplates]="messageTemplates">
      </edit-templates-form>
    </div>
  </app-modal>
  `,
  styleUrls: ['./templates-modal.component.scss']
})
export class TemplatesModalComponent implements OnInit, OnDestroy {
  templatesDropdown = new FormControl('');
  visible: boolean;
  currentTemplate: MessageTemplate;
  messageTemplates: MessageTemplate[];
  messageTemplatesOptions: any[] = [];
  messageTemplateId: any;
  campaignId: number;
  chooseTemplatePrompt = helpContent.CHOOSE_TEMPLATE_PROMPT;
  addMessageTemplatePrompt = helpContent.ADD_TEMPLATE_PROMPT;

  private messageTemplatesSub$: Subscription;

  constructor(
    private store: Store<fromRoot.AppState>,
  ) {
    this.messageTemplatesSub$ = this.store.select(messageTemplatesFeatureKey)
      .subscribe(messageTemplatesState => {
        this.messageTemplates = messageTemplatesState.messageTemplates;
        this.messageTemplatesOptions = this.messageTemplates.map((messageTemplate: MessageTemplate) => {
          return { label: messageTemplate.nickname, value: messageTemplate.id };
        });
      });

    useModalManagerStore.subscribe(s => s.associateTemplate, (s) => { this.visible = s });

    this.templatesDropdown.valueChanges.subscribe(value => {
      this.currentTemplate = this.messageTemplates.find((messageTemplate: MessageTemplate) => messageTemplate.id === +value);
      this.messageTemplateId = this.currentTemplate?.id;
      this.campaignId = useTemplatesStore.getState().campaignId;
    });
  }

  onClose() {
    useModalManagerStore.setState({ associateTemplate: false });
  }

  onSave() {
    this.campaignId = useTemplatesStore.getState().campaignId;
    this.store.dispatch(CampaignsActions.updateCampaign({
      campaign: { id: this.campaignId, message_template_id: this.messageTemplateId, enabled: true }
    }));
    useToastStore.setState({ isVisible: true, content: 'Campaign updated successfully' });
    this.onClose();
  }

  handleValueChange(e: any) {
    this.templatesDropdown.setValue(e.target.value);
  }

  ngOnInit(): void {
    if (!this.messageTemplates?.length) {
      this.store.dispatch(MessageTemplatesActions.getMessageTemplates());
    }
  }

  ngOnDestroy() {
    if (this.messageTemplatesSub$) {
      this.messageTemplatesSub$.unsubscribe();
    }
    this.templatesDropdown.reset();
  }

}
