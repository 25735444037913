import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal',
  template: `
    <div
      *ngIf="visible"
      class="backdrop"
      [ngStyle]="{
        'background-color': isDarkTheme
          ? 'rgba(255,255,255, 0.15)'
          : 'rgba(244,244,244, 0.85)'
      }"
    >
      <div
        class="modal-box"
        [style]="{height, width}"
        [ngClass]="{'dark-theme': isDarkTheme, 'modal-box__drop-shadow': useShadow}"
        [ngStyle]="{
          padding: usePadding ? '16px' : '0',
          background: useBackground ? '#fff' : 'transparent',
          background: isDarkTheme ? '#04223A' : 'transparent',
        }"
      >
        <div *ngIf="useActionBar" class="flex action-bar">
          <div class="flex__row--space-between action-content">
            <span *ngIf="useHeader" class="s4 header">{{ header }}</span>
            <div class="flex actions-wrapper">
              <ng-content select="[actions]"></ng-content>
            </div>
          </div>
        </div>
        <div class="modal-content">
          <ng-content></ng-content>
        </div>
      </div>
      <div *ngIf="useSideBar" class="side-bar flex__column">
        <modal-side-bar [header]="sideBarHeader">
          <div sideBarContent>
            <ng-content select="[sideBar]"> </ng-content>
          </div>
        </modal-side-bar>
      </div>
    </div>
  `,
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() visible?: boolean;
  @Input() height: string;
  @Input() width: string;
  @Input() usePadding: boolean = true;
  @Input() useBackground: boolean = true;
  @Input() useShadow: boolean = true;
  @Input() useActionBar: boolean = false;
  @Input() header: string;
  @Input() isDarkTheme?: boolean = false;
  @Input() useHeader?: boolean = true;

  // Side Bar
  @Input() sideBarHeader?: string;
  @Input() useSideBar?: boolean = false;

  ngOnInit(): void {
    if (this.useActionBar) {
      this.useBackground = false;
      this.useShadow = false;
    }
  }

  constructor() { }
}
