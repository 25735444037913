import { Component, Input, OnInit } from '@angular/core';
import { useNavigationStore } from 'src/app/store/navigation.store';

@Component({
  selector: 'app-nav-cell',
  template: `
    <div class="nav-cell flex">
    <div class="flex nav-cell--btn {{activeClass}}">
      <span *ngIf="activeClass" class="active-divider"></span>
        <img draggable="false" class="nav-icon" src="{{icon}}" alt="nav icon" />
        <span class="nav-cell-label {{activeClass}}">{{text}}</span>
    </div>
    </div>
`,
  styleUrls: ['./nav-cell.component.scss']
})
export class NavCellComponent implements OnInit {
  @Input() icon: string;
  @Input() activeClass: string;
  @Input() isActive: boolean = false;
  @Input() text: string;
  lightTheme: boolean

  constructor() {
    useNavigationStore.subscribe(s => s.currentPage, newActiveRoute => {
      this.lightTheme = newActiveRoute === 'billing-plans'
    })
  }

  ngOnInit(): void {
  }

}
