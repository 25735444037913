import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RequestHelpersService } from '../shared/services/request-helpers.service';
import { UserState } from '../store/user.store';
import { map, Observable, throwError, catchError } from 'rxjs';
import { useToastStore } from '../store/toast.store';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {
  private url: string = `${environment.apiUrl}/clients`;
  private createHeaders = () => this.requestHelpers.createHeaders();

  constructor(
    private http: HttpClient,
    private requestHelpers: RequestHelpersService
  ) { }

  // Fetch user profile
  getUserProfile(): Observable<UserState> {
    const id = this.requestHelpers.getClientId();
    return this.http
      .get<UserState>(`${this.url}/${id}`, { headers: this.createHeaders() })
      .pipe(
        map((user: UserState) => {
          return user;
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => new Error('error getting user profile'))
        })
      );
  }

  // Update user profile
  updateUserProfile(userProfile: UserState): Observable<UserState> {
    const id = this.requestHelpers.getClientId();
    return this.http
      .put<UserState>(`${this.url}/${id}`, userProfile, { headers: this.createHeaders() })
      .pipe(
        map((user: UserState) => {
          return user;
        }),
        catchError((error) => {
          this.handleError(error);
          return throwError(() => new Error('error updating user profile'))
        })
      );
  }


  private handleError(err: HttpErrorResponse) {
    if (err.error instanceof Error) {
    }
    if (err.status === 409) {
      useToastStore.setState({
        isVisible: true,
        content: `Email already in use.`,
        variant: 'danger',
      });
    } else {
      useToastStore.setState({
        isVisible: true,
        content: `Opps looks like an error occurred.`,
        variant: 'danger',
      });
    }
  }
}
