import { Component, Input, OnInit } from '@angular/core';
import { useLinkedinConnectionsStore } from 'src/app/store/linkedin-connections';

@Component({
  selector: 'activity-log-cell',
  templateUrl: './activity-log-cell.component.html',
  styleUrl: './activity-log-cell.component.scss',
})
export class ActivityLogCellComponent {
  @Input() icon?: string;
  @Input() activityType: string;
  @Input() activityDescription: string;
  @Input() dateTime: Date;
  iconsWithCustomWidth = ['assets/icons/link.svg'];

  isCustomIcon = (icon) => this.iconsWithCustomWidth.includes(icon);

  constructor() {}
}
